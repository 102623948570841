import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ORDERED_STRING_COMPARATOR } from "../../../../domain/Domain.Comparators"
import { BuildingListItem } from "../../../../domain/portal/admin/buildings/Buildings.Model"
import { OpenButton } from "../../../../uikit/button/OpenButton"
import { StatusView } from "../../../../uikit/label/StatusView"
import { Paper } from "@mui/material"
import { SuccessAlert, TableRowErrorAlert } from "../../../../uikit/Shared.Alert"
import { DEFAULT_ROWS_PER_PAGE } from "../../../../uikit/Shared.Consts"
import { TableFixed } from "../../../../uikit/table/Table.Fixed"
import { TableColumnSort, TableHeaderView } from "../../../../uikit/table/Table.HeaderView"
import { PageRowSlice, TablePaginationView } from "../../../../uikit/table/Table.PaginationView"
import { TableRowClickable, TableRowView } from "../../../../uikit/table/Table.RowView"
import { firstViewState } from "../../../Shared.Reducer"
import { mapDispatchToProps } from "./BuildingsList.Connect"
import { BuildingsListState } from "./BuildingsList.Reducer"
import { SharedListHeader } from "../sharedListComponet/SharedListHeader.Component"

enum BuildingsColumns {
  STATUS_TYPE = "STATUS_TYPE",
  BUILDING_OBJECT = "BUILDING_OBJECT",
  ADDRESS = "ADDRESS",
  AREA = "AREA",
}

interface BuildingsListComponentProps extends BuildingsListState, ReturnType<typeof mapDispatchToProps> {}

export const BuildingsListComponent = (props: BuildingsListComponentProps) => {
  const { t } = useTranslation("buildings")
  const { viewState, getBuildings, navigateToBuilding, navigateToArea, showDeleteSuccess } = props

  useEffect(() => {
    if (firstViewState(viewState)) {
      getBuildings()
    }
  }, [viewState, getBuildings])

  const buildingList = useMemo(() => viewState.domainResult?.buildings || [], [viewState])

  const [orderBy, setOrderBy] = useState<TableColumnSort<BuildingsColumns>>({
    column: BuildingsColumns.BUILDING_OBJECT,
    direction: "asc",
  })

  const [pageRowSlice, setPageRowSlice] = useState<PageRowSlice>({
    start: 0,
    end: DEFAULT_ROWS_PER_PAGE,
  })

  const [filterQuery, setFilterQuery] = useState<string>("")

  const tableHeaders = [
    {
      column: BuildingsColumns.STATUS_TYPE,
      label: t("list.label.status"),
      width: "10%",
    },
    {
      column: BuildingsColumns.BUILDING_OBJECT,
      label: t("list.label.building-object"),
      width: "30%",
    },
    {
      column: BuildingsColumns.ADDRESS,
      label: t("list.label.address"),
      width: "30%",
    },
    {
      column: BuildingsColumns.AREA,
      label: t("field.label.area"),
      width: "30%",
    },
  ]

  const columnComparator = () => {
    switch (orderBy.column) {
      case BuildingsColumns.STATUS_TYPE:
        return (a: BuildingListItem, b: BuildingListItem) =>
          ORDERED_STRING_COMPARATOR(a.sortableStatusType, b.sortableStatusType, orderBy.direction)
      case BuildingsColumns.BUILDING_OBJECT:
        return (a: BuildingListItem, b: BuildingListItem) =>
          ORDERED_STRING_COMPARATOR(a.buildingObject, b.buildingObject, orderBy.direction)
      case BuildingsColumns.ADDRESS:
        return (a: BuildingListItem, b: BuildingListItem) =>
          ORDERED_STRING_COMPARATOR(a.address, b.address, orderBy.direction)
      case BuildingsColumns.AREA:
        return (a: BuildingListItem, b: BuildingListItem) =>
          ORDERED_STRING_COMPARATOR(a.areaName, b.areaName, orderBy.direction)
    }
  }

  return (
    <>
      {showDeleteSuccess && <SuccessAlert message={t("list.action.delete.success")} />}
      <Paper>
        <SharedListHeader
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          header={{
            title: t("list.title"),
            showButton: false,
            showFilter: true,
          }}
        />
        <TableContainer>
          <TableFixed>
            <TableHeaderView<BuildingsColumns>
              isLoading={viewState.isLoading}
              headers={tableHeaders}
              orderBy={orderBy}
              orderByChanged={(orderBy) => setOrderBy(orderBy)}
            />
            {viewState.domainError && (
              <TableRowErrorAlert colSpan={4} retry={() => getBuildings()} message={viewState.domainError.message} />
            )}
            <TableRowView<BuildingListItem>
              colSpan={4}
              rows={buildingList}
              pageRowSlice={pageRowSlice}
              comparator={columnComparator}
              filterQuery={filterQuery}
              render={(buildingItem) => (
                <TableRowClickable<BuildingListItem>
                  key={buildingItem.id}
                  rowData={buildingItem}
                  rowClick={(buildingItem) => navigateToBuilding(buildingItem.id)}
                >
                  <TableCell align="left">
                    <StatusView statusType={buildingItem.statusType} />
                  </TableCell>
                  <TableCell align="left">{buildingItem.buildingObject}</TableCell>
                  <TableCell align="left">{buildingItem.address}</TableCell>
                  <TableCell align="left">
                    <OpenButton label={buildingItem.areaName} open={() => navigateToArea(buildingItem.areaId)} />
                  </TableCell>
                </TableRowClickable>
              )}
            />
          </TableFixed>
          <TablePaginationView rowCount={buildingList.length} onPageRowSliceChanged={setPageRowSlice} />
        </TableContainer>
      </Paper>
    </>
  )
}
