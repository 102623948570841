import { useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { Form, Formik } from "formik"
import { getMeterById, updateMeterById } from "../../../../../domain/portal/admin/meters/Meters.Repository"
import {
  getContractManagementById,
  getContractPricePackages,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"

import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { meterUpdateSchema } from "./MetersValidation.Schema"
import MeterCreateUpdateFormFields from "./MeterCreateUpdateFormFields"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import {
  Medium,
  MeterAttachmentType,
  MeterResponse,
  MeterUpdateAdminRequest,
} from "../../../../../data/generated-sources/openapi"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"

const MeterUpdateForm = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const {
    match: { params },
    history,
  } = props
  const { t } = useTranslation("meters")
  const [meterError, setMeterError] = useState<AxiosErrorDataType>()

  const {
    data: meter,
    isLoading: isMeterLoading,
    isFetched: isMeterFetched,
    remove: removeMeter,
  } = useQuery(["getMeter"], () => getMeterById(params?.meterId), {
    enabled: !!params?.meterId,
    ...useQueryDefaultOptions,
    onError: setMeterError,
  })

  const { data: pricePackages, remove: removePrices } = useQuery(
    ["getPricePackages"],
    () => getContractPricePackages(meter?.contractId ?? ""),
    {
      enabled: !!meter?.contractId,
      ...useQueryDefaultOptions,
      onError: setMeterError,
    },
  )

  const { data: contract, remove: removeContract } = useQuery(
    ["getContract"],
    () => getContractManagementById(meter?.contractId ?? ""),
    {
      enabled: !!meter?.contractId,
      ...useQueryDefaultOptions,
      onError: setMeterError,
    },
  )

  useEffect(() => {
    return () => {
      removeMeter()
      removePrices()
      removeContract()
    }
  }, [removeMeter, removePrices, removeContract])

  const { mutate: updateMeter, isLoading: isMeterUpdating } = useMutation(
    ["updateMeter"],
    (meterData: MeterUpdateAdminRequest) => updateMeterById(params.meterId, meterData),
    {
      onSuccess: () => history.goBack(),
      onError: setMeterError,
    },
  )

  const handleOnSubmit = (values: MeterResponse) => {
    const isElectricityUnit =
      values.medium === Medium.ELECTRICITY && values.meterAttachmentType === MeterAttachmentType.UTILITY_UNIT

    const payload: MeterUpdateAdminRequest = {
      meteringCode: values.meteringCode,
      medium: values.medium,
      billableFrom: values.billableFrom ?? "",
      ...(values.billableTo && { billableTo: values.billableTo }),
      ...(isElectricityUnit && {
        ...(values.powerMeterType && { powerMeterType: values.powerMeterType }),
        ...(Number.isInteger(values.pricePackageId) && { pricePackageId: values.pricePackageId }),
      }),
      ...(values.meterAttachmentType === MeterAttachmentType.BUILDING && {
        usageType: values.usageType,
      }),
    }
    setMeterError(undefined)
    updateMeter(payload)
  }

  if ((isMeterLoading && isMeterFetched) || !meter) return <FullScreenLoader />

  return (
    <>
      <ErrorAlert
        visible={!!meterError}
        message={t(`error-codes:${meterError?.response?.data?.code || meterError?.code || "OTHER"}`)}
      />
      <Formik<MeterResponse>
        initialValues={meter}
        enableReinitialize
        validationSchema={meterUpdateSchema}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <Paper>
              <MeterCreateUpdateFormFields<MeterResponse>
                {...props}
                isEditMeter
                contractStartDate={contract?.startDate}
                pricePackagesOptions={pricePackages || []}
              />
            </Paper>
            <FormUpdateActionsView
              {...props}
              navigateBack={() => history.goBack()}
              buttonCtaLabel={t("label.saveChanges")}
              isLoading={(isMeterLoading && isMeterFetched) || isMeterUpdating}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default MeterUpdateForm
