import { useEffect, useState } from "react"
import { Badge, Box, CircularProgress, Typography } from "@mui/material"
import { RouteComponentProps } from "react-router"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import {
  customerGetUtilityUnitById,
  customerGetUtilityUnitBillingsById,
} from "../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import TabPanel from "../../../../uikit/tabs/TabPanel"
import { IconPositionEnum, PrimaryTab, PrimaryTabContainer } from "../../../../uikit/tabs/PrimaryTab"
import { UtilityUnitParticipations } from "./components/participations/UtilityUnitParticipations"
import { useAppDispatch } from "../../../hooks"
import { setPageSubtitle } from "../../../appcontainer/AppContainer.Epic"
import UtilityUnitBillings from "./components/billings/UtilityUnitBillings"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../Shared.Utils"
import { UtilityUnitMetersTable } from "./components/meters/UtilityUnitMetersTable"
import EnergyConsumption from "./components/energyConsumption/EnergyConsumption"

export default TabPanel
export const UtilityUnitDetail = ({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { utilityUnitId, areaId } = params
  const { t } = useTranslation("utilityUnit")
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const [error, setError] = useState<AxiosErrorDataType>()
  const [selectedTab, setSelectedTab] = useState(0)
  const [currentParticipantName, setCurrentParticipantName] = useState<string>("")
  const [billingsHasNewBills, setBillingsHasNewBills] = useState<boolean>(false)

  const {
    isLoading: isLoadingUnit,
    isFetching: isFetchingUnit,
    remove: removeUnit,
    data: utilityUnit,
  } = useQuery(["getUnit"], () => customerGetUtilityUnitById(utilityUnitId), {
    enabled: !!utilityUnitId,
    ...useQueryDefaultOptions,
    onError: setError,
  })

  const {
    data: bills,
    isError: isErrorBills,
    isFetching: isLoadingBills,
    remove: removeBills,
  } = useQuery(["getUtilityUnitBillings"], () => customerGetUtilityUnitBillingsById(utilityUnitId), {
    enabled: !!utilityUnitId,
    ...useQueryDefaultOptions,
    onError: setError,
    onSuccess: (data) => {
      const hasNewBills = data.find((bill) => bill?.isNew)
      setBillingsHasNewBills(!!hasNewBills)
    },
  })

  const utilityUnitName = utilityUnit?.name || ""

  useEffect(() => {
    dispatch(setPageSubtitle(utilityUnitName))
  }, [utilityUnitName])

  useEffect(() => {
    queryClient.removeQueries(["getMeters"])
    queryClient.removeQueries(["getParticipations"])
    queryClient.removeQueries(["customerGetEnergyConsumption"])
  }, [])

  useEffect(() => {
    return () => {
      removeUnit()
      removeBills()
    }
  }, [removeUnit, removeBills])

  if (isLoadingUnit && isFetchingUnit) return <CircularProgress />
  return (
    <>
      <ErrorAlert
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <Box px={6} py={5}>
        <Typography fontWeight={700} pb={2.5}>
          {t("managerDetails.information")}
        </Typography>
        <Typography variant="subtitle1">{t("managerDetails.type")}</Typography>
        <Typography pb={4}>{t(`utilityType.${utilityUnit?.usageType}`)}</Typography>

        <Typography variant="subtitle1">{t("managerDetails.currentParticipants")}</Typography>
        <Typography pb={4}>
          {currentParticipantName || t("utilityUnitParticipations:participation.noParticipation")}
        </Typography>

        <Typography variant="subtitle1">{t("managerDetails.unitSpace")}</Typography>
        <Typography pb={2}>{utilityUnit?.unitSpace?.toFixed(2)}</Typography>
      </Box>
      <PrimaryTabContainer value={selectedTab} variant="scrollable" onChange={(_, tab) => setSelectedTab(tab)}>
        <PrimaryTab label={t("managerDetailsTab.participantHistory")} />
        <PrimaryTab
          label={t("managerDetailsTab.settlements")}
          iconPosition={IconPositionEnum.END}
          icon={
            selectedTab !== 1 && billingsHasNewBills ? (
              <Badge badgeContent={""} color="warning" sx={{ mx: 2 }} />
            ) : (
              <></>
            )
          }
        />
        <PrimaryTab label={t("managerDetailsTab.consumptionData")} />
        <PrimaryTab label={t("managerDetailsTab.counterSettings")} />
      </PrimaryTabContainer>
      <TabPanel value={selectedTab} index={0}>
        <UtilityUnitParticipations
          utilityUnitId={utilityUnitId}
          currentParticipantName={(fullName) => setCurrentParticipantName(fullName)}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <UtilityUnitBillings
          bills={bills || []}
          areaId={areaId}
          contractId={utilityUnit?.contractId ?? ""}
          error={error}
          isError={isErrorBills}
          isLoading={isLoadingBills}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <UtilityUnitMetersTable utilityUnitId={utilityUnitId} contractId={utilityUnit?.contractId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <EnergyConsumption utilityUnitId={utilityUnitId} />
      </TabPanel>
    </>
  )
}
