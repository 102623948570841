import { GridRenderCellParams } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { Typography } from "@mui/material"

import { StatusType } from "../../../../domain/Domain.Model"
import { StatusView } from "../../../../uikit/label/StatusView"
import {
  DefaultCell,
  statusSortComparator,
  filterByStatus,
  filterByDate,
  getApplyQuickDateFilter,
  getApplyQuickStatusFilter,
} from "../../../../uikit/dataGridTable"
import DateUtils from "../../../../services/utils/DateUtils"
import { t } from "i18next"

const DateCell = ({ value }: GridRenderCellParams) => (
  <Typography fontWeight={400} fontSize={14}>
    {value ? DateUtils.getDeFormatWithDots(value) : "-"}
  </Typography>
)

export const contractColumns = (): Array<GridBaseColDef> => {
  const contractColumnsConf: Array<GridBaseColDef> = [
    {
      flex: 100,
      field: "activeState",
      headerName: t("shared:status.label"),
      renderCell: ({ value }) => <StatusView statusType={value as StatusType} />,
      filterOperators: filterByStatus,
      sortComparator: statusSortComparator,
      getApplyQuickFilterFn: getApplyQuickStatusFilter,
    },
    {
      flex: 200,
      field: "name",
      headerName: t("contracts:table.header.contractName"),
      renderCell: DefaultCell,
    },
    {
      flex: 200,
      field: "areaName",
      headerName: t("contracts:table.header.area"),
      renderCell: DefaultCell,
    },
    {
      flex: 200,
      field: "productName",
      headerName: t("contracts:table.header.product"),
      valueGetter: (_, row) => row?.product?.name,
      renderCell: DefaultCell,
    },
    {
      flex: 120,
      field: "startDate",
      headerName: t("contracts:table.header.startDate"),
      renderCell: DateCell,
      valueFormatter: (value) => (value ? DateUtils.getDeFormatWithDots(value) : "-"),
      filterOperators: filterByDate,
      getApplyQuickFilterFn: getApplyQuickDateFilter,
    },
    {
      flex: 120,
      field: "endDate",
      headerName: t("contracts:table.header.endDate"),
      valueFormatter: (value) => (value ? DateUtils.getDeFormatWithDots(value) : "-"),
      renderCell: DateCell,
      filterOperators: filterByDate,
      getApplyQuickFilterFn: getApplyQuickDateFilter,
    },
  ]

  return contractColumnsConf
}
