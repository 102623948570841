import { useQuery } from "@tanstack/react-query"
import { getMeterById } from "../../../../domain/portal/admin/meters/Meters.Repository"
import { getAreaById } from "../../../../domain/portal/admin/areas/Areas.Repository"
import {
  getBillingSettingsByContractId,
  getContactByContractId,
  getContractManagementById,
} from "../../../../domain/portal/admin/contracts/Contracts.Repository"
import { getUtilityUnitById } from "../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { useQueryDefaultOptions } from "../../../Shared.Utils"

export const GetMeterHook = (props: { id: string }) => {
  const { id } = props
  const response = useQuery(["getMeter"], () => getMeterById(id), useQueryDefaultOptions)
  return [response] as const
}

export const GetAreaHook = (props: { id: string }) => {
  const { id } = props
  const response = useQuery(["getAreaById"], () => getAreaById(id), useQueryDefaultOptions)
  return [response] as const
}

export const GetContractManagementHook = (props: { id: string }) => {
  const { id } = props
  const response = useQuery(["getContractManagement"], () => getContractManagementById(id), useQueryDefaultOptions)
  return [response] as const
}

export const GetContractManagementContactHook = (props: { id: string }) => {
  const { id } = props
  const response = useQuery(["getContractManagementContact"], () => getContactByContractId(id), useQueryDefaultOptions)
  return [response] as const
}

export const GetContractManagementBillingSettingsHook = (props: { id: string }) => {
  const { id } = props
  const response = useQuery(
    ["getContractManagementBillingSettings"],
    () => getBillingSettingsByContractId(id),
    useQueryDefaultOptions,
  )
  return [response] as const
}

export const GetUtilityUnitById = ({ id }: { id: string }) => {
  const response = useQuery(["getUtilityUnit"], () => getUtilityUnitById(id), useQueryDefaultOptions)
  return [response] as const
}
