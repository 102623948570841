import { useEffect, useMemo, useState } from "react"
import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { useTranslation } from "react-i18next"
import { FormRowCell, FormRowColumn, FormView } from "../../../../../../uikit/form/FormView"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"
import { validationError } from "../../../../../Shared.Validation"
import {
  BillingsRecurringUpsert,
  HalfOfYear,
} from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Checkbox,
  Box,
  FormGroup,
  FilterOptionsState,
} from "@mui/material"
import { SingleLineTextField } from "../../../../../../uikit/input/SingleLineTextField"
import { isEqual, isBefore, isAfter } from "date-fns"
import { ContractAdminResponse } from "../../../../../../data/generated-sources/openapi"

interface BillingsRecurringFormProps {
  contracts: ContractAdminResponse[]
}

export function BillingsRecurringForm(
  props: BillingsRecurringFormProps &
    Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikState<BillingsRecurringUpsert>, "errors" | "values" | "touched"> &
    Pick<FormikHelpers<BillingsRecurringUpsert>, "setFieldValue">,
) {
  const { t } = useTranslation("billings-recurring")
  const { contracts, handleBlur, touched, setFieldValue, values, errors } = props
  useEffect(() => {
    const [startMonth, endMonth] = values.halfOfYear === HalfOfYear.FIRST ? ["01-01", "06-30"] : ["07-01", "12-31"]
    const year = values.year.getFullYear()
    setFieldValue("startDate", new Date(`${year}-${startMonth}`))
    setFieldValue("endDate", new Date(`${year}-${endMonth}`))
  }, [values?.halfOfYear, values?.year, setFieldValue])

  const filteredContractsList = useMemo(
    () =>
      contracts.filter(
        (contract: ContractAdminResponse) =>
          beforeOrEqual(new Date(contract.startDate), values.endDate) &&
          afterOrEqual(contract.endDate ? new Date(contract.endDate) : new Date(), values.startDate),
      ),
    [values.startDate, values.endDate, contracts],
  )

  const [useAllContracts, setUseAllContracts] = useState(false)
  useEffect(() => {
    setFieldValue("selectedContract", useAllContracts ? filteredContractsList.map((contract) => contract.id) : [])
  }, [useAllContracts, filteredContractsList, setFieldValue])

  return (
    <FormView>
      <FormRowColumn>
        <FormRowCell>
          <SingleLineDatePicker
            name="startDate"
            label={t("form.field.startDate")}
            helperText={validationError(errors.startDate, touched.startDate)}
            value={new Date(values.year).setHours(0, 0, 0, 0)}
            onChange={(value) => {
              setFieldValue("year", new Date(value))
            }}
            onBlur={handleBlur}
            views={["year"]}
          />
        </FormRowCell>
        <FormRowCell>
          <SingleLineDatePicker
            disabled
            name="endDate"
            label={t("form.field.endDate")}
            value={new Date(values.endDate).setHours(0, 0, 0, 0)}
            onChange={(value) => setFieldValue("endDate", value)}
            onBlur={handleBlur}
          />
        </FormRowCell>
      </FormRowColumn>
      <FormRowColumn>
        <FormRowCell>
          <RadioGroup row color="secondary" name="moveOutType" value={values.halfOfYear}>
            <FormControlLabel
              value={HalfOfYear.FIRST}
              control={<Radio required onClick={() => setFieldValue("halfOfYear", HalfOfYear.FIRST)} />}
              label={`${t("form.field.firstHalfYear")}`}
            />
            <FormControlLabel
              value={HalfOfYear.SECOND}
              control={<Radio required onClick={() => setFieldValue("halfOfYear", HalfOfYear.SECOND)} />}
              label={`${t("form.field.secondHalfYear")}`}
            />
          </RadioGroup>
        </FormRowCell>
      </FormRowColumn>
      <FormRowColumn>
        <FormRowCell>
          <ContractsFilterView
            selectedItems={values.selectedContract}
            label={t("form.field.selectContract")}
            contracts={filteredContractsList}
            onSelectionChanged={(selectedContracts) => setFieldValue("selectedContract", selectedContracts)}
          />
        </FormRowCell>
      </FormRowColumn>
      <FormRowColumn>
        <Box ml={1}>
          <FormGroup>
            <FormControlLabel
              onClick={() => setUseAllContracts(!useAllContracts)}
              control={<Checkbox checked={useAllContracts} />}
              label={`${t("form.field.allContracts")}`}
            />
          </FormGroup>
        </Box>
      </FormRowColumn>
    </FormView>
  )
}

function beforeOrEqual(target: Date, comparison: Date): boolean {
  return isEqual(target, comparison) || isBefore(target, comparison)
}

function afterOrEqual(target: Date, comparison: Date): boolean {
  return isEqual(target, comparison) || isAfter(target, comparison)
}

const ContractsFilterView = (props: {
  selectedItems: string[]
  label: string
  contracts: ContractAdminResponse[]
  onSelectionChanged: (values: string[]) => void
}) => {
  const { selectedItems, label, contracts, onSelectionChanged } = props
  return (
    <Autocomplete
      multiple
      value={contracts.filter((contract) => selectedItems.includes(contract.id))}
      options={contracts}
      filterOptions={(options: ContractAdminResponse[], state: FilterOptionsState<ContractAdminResponse>) => {
        const query = state.inputValue.toLowerCase()
        const foundItems = options.filter((item: ContractAdminResponse) => item.name.toLowerCase().includes(query))
        return foundItems
      }}
      getOptionLabel={(contract) => contract.name}
      onChange={(_, value, reason) => {
        if ((reason === "selectOption" || reason === "removeOption") && value !== null) {
          onSelectionChanged(value.map((contract) => contract.id))
        }
        if (reason === "clear") {
          onSelectionChanged([])
        }
      }}
      renderOption={(props, option) => {
        return (
          <p
            key={option.id + option.name}
            onClick={() => {
              onSelectionChanged([...selectedItems, option.id])
            }}
          >
            {option.name}
          </p>
        )
      }}
      renderInput={(params) => <SingleLineTextField {...params} label={label} />}
    />
  )
}
