import { Box, styled, Typography } from "@mui/material"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { AllParticipantsBillingState } from "../../../../../data/generated-sources/openapi"
import { getTranslateValue } from "../../../../Shared.Utils"
import { DefaultCell } from "../../../../../uikit/dataGridTable"

export const WaitingDataTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: theme.typography.pxToRem(13),
  flexWrap: "wrap",
}))

export const periodicBillingsColumns = (): Array<GridBaseColDef> => {
  return [
    {
      field: "datePeriod",
      headerName: getTranslateValue("settlements:section-list-header.bill.period"),
      sortComparator: (v1, v2) => (v1?.startDate > v2?.startDate ? 1 : -1),
      renderCell: DefaultCell,
      flex: 200,
    },
    {
      field: "totalSum",
      headerName: getTranslateValue("settlements:section-list-header.bill.total"),
      renderCell: DefaultCell,
      flex: 150,
    },
    {
      field: "activeState",
      headerName: getTranslateValue("settlements:section-list-header.bill.status"),
      valueGetter: (_, row) => row,
      sortComparator: (v1, v2) =>
        getTranslateValue(`settlements:list-billing-status.${v1?.activeState}`) >
        getTranslateValue(`settlements:list-billing-status.${v2?.activeState}`)
          ? 1
          : -1,
      renderCell: ({ value }) => {
        let waitMsg = ""
        const isWaitingForDataStatus = value.activeState === AllParticipantsBillingState.WAITING_FOR_DATA

        if (isWaitingForDataStatus) {
          waitMsg = getTranslateValue("settlements:list-billing-status.WAITING_FOR_DATA_ETA_PAST")
          if (value?.estDataArrivalDate) {
            waitMsg = getTranslateValue("settlements:list-billing-status.WAITING_FOR_DATA_ETA", {
              estDate: value?.estDataArrivalDate,
            })
          }
        }

        return (
          <Box>
            <Typography fontSize={14}>
              {getTranslateValue(`settlements:list-billing-status.${value?.activeState}`)}
            </Typography>
            {isWaitingForDataStatus && <WaitingDataTypography>{waitMsg}</WaitingDataTypography>}
          </Box>
        )
      },
      flex: 200,
    },
    {
      field: "contractType",
      headerName: getTranslateValue("settlements:section-list-header.bill.contractType"),
      valueGetter: (_, row) => row?.productName || "-",
      renderCell: DefaultCell,
      flex: 200,
    },
  ]
}
