import { Typography } from "@mui/material"
import { IndividualParticipantBillingItem } from "../../../../domain/portal/manager/billings/Billings.Model"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { useHistory } from "react-router-dom"
import { filterArrayBySearchInput } from "../../../Shared.Utils"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { DataGridTable, DefaultCell } from "../../../../uikit/dataGridTable"

interface IndividualDoneBillingsProps {
  areaId: string
  contractId: string
  bills: IndividualParticipantBillingItem[]
}

export const IndividualDoneBillings = ({ areaId, contractId, bills }: IndividualDoneBillingsProps) => {
  const history = useHistory()
  const { t } = useTranslation("settlements")
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [billingsList, setBillingsList] = useState<IndividualParticipantBillingItem[]>()

  const navigateToIndividualParticipantBillingFinalize = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/individual/${billingId}/finalize`)
  }

  const filterData = (searchInput: string) => {
    if (bills) {
      const filtered = filterArrayBySearchInput<IndividualParticipantBillingItem>(bills, searchInput)
      setBillingsList(filtered)
    }
  }

  useEffect(() => {
    setBillingsList(bills)
  }, [bills])

  const billingsColumns: Array<GridBaseColDef> = [
    {
      field: "datePeriod",
      headerName: t("section-list-header.bill.period"),
      sortComparator: (v1, v2) => (v1?.startDate > v2?.startDate ? 1 : -1),
      renderCell: DefaultCell,
      flex: 200,
    },
    {
      field: "buildingName",
      headerName: t("section-list-header.bill.building"),
      renderCell: DefaultCell,
      flex: 150,
    },
    {
      field: "utilityUnitName",
      headerName: t("utilityUnit:table.label.unit"),
      renderCell: DefaultCell,
      flex: 150,
    },
    {
      field: "participantName",
      headerName: t("section-list-header.bill.subscriber"),
      renderCell: ({ value }) =>
        value ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <Typography variant="subtitle2" color={"#6C9C30"}>
            {t("list.noParticipation.name")}
          </Typography>
        ),
      flex: 150,
    },
    {
      field: "activeState",
      headerName: t("section-list-header.bill.status"),
      sortComparator: (v1, v2) =>
        t(`list-billing-status.${v1?.activeState}`) > t(`list-billing-status.${v2?.activeState}`) ? 1 : -1,
      renderCell: ({ value }) => <Typography variant="subtitle2">{t(`list-billing-status.${value}`)}</Typography>,
      flex: 150,
    },
  ]

  return (
    <>
      <Typography variant="h5" fontWeight={400} mt={10} mb={5}>
        {t("section.title.doneBills")}
      </Typography>
      <SingleLineTextField
        id="filter"
        name="filter"
        type="text"
        sx={{ width: "24rem", mb: 4 }}
        label={t("shared:form.search.label")}
        value={filterQuery}
        onChange={(event) => {
          setFilterQuery(event.target.value)
          filterData(event.target.value)
        }}
      />
      <DataGridTable<IndividualParticipantBillingItem>
        rows={billingsList || []}
        columns={billingsColumns}
        getRowClassName={({ row }) => (row.makeBill ? "RowClickable" : "RowNotClickable")}
        onRowClick={({ row }) => {
          if (!row?.makeBill) return
          navigateToIndividualParticipantBillingFinalize(row?.id)
        }}
        defaultPageSize={25}
      />
    </>
  )
}
