import { I18nNamespace } from "../../../../App.i18n"

export const MediumI18n: I18nNamespace = {
  name: "medium",
  de: {
    "detail.title": "MEDIUM",
    "costShares.title": "Kostenanteile und Zählerkonfiguration",
    "tab.label.ELECTRICITY": "Elektrizität",
    "label.COOLING_BILL": "Kältekosten",
    "label.HEATING_BILL": "Wärmekosten",
    "label.WATER_HEATING": "Warmwasser",
    "label.COOLING_WATER": "Kaltwasser",
    "label.WATER_BILL": "Wasserkosten",
    "label.ROOM_HEATING": "Heizung",
    "label.municipality": "Gemeinde",
    "label.incomingMeters": "Zählpunkte Bezug",
    "label.outgoingMeters": "Zählpunkte Einspeisung",
    "label.basicCost": "Grundkosten",
    "label.consumptionBasedCost": "Verbrauchsabhängige Kosten",
    "label.consumptionCost": "Verbrauchsabhängig",
    "label.commercialUnits": "Gewerbeeinheiten",
    "label.residentalUnits": "Wohneinheiten",
    "label.saveBtn": "Medium speichern",
    "label.INCOMING": "Bezug",
    "label.OUTGOING": "Einspeisung",
    "label.costAllocationTitle": "Ist die Kostenaufteilung so korrekt?",
    "label.deliveryType": "Zählpunkt Typ",
    "table.header.deliveryId": "Zählpunkt-ID",
    "table.header.startDate": "Startdatum",
    "table.header.endDate": "Enddatum",
    "confirmDialog.title": "Wollen Sie den Abrechnungsbereich, inkl. Kostenanteile und Zählerkonfiguration, löschen?",
    "confirmDialog.button": "Abrechnungsbereich löschen",

    "label.groupMeters.HEATING_BILL": "Wärmekosten Zählerkonfiguration",
    "label.groupMeters.COOLING_BILL": "Kältekosten Zählerkonfiguration",
    "label.groupMeters.WATER_BILL": "Wasserkosten Zählerkonfiguration",
    "label.heating.meterTotalConsumption": "Wärmezähler Gesamtverbrauch",
    "label.cooling.meterTotalConsumption": "Kältezähler",
    "label.water.meterTotalConsumption": "Verteilschlüssel für verbrauchsabhängigen Anteil",
    "label.heating.meterHeating": "Wärmezähler Heizung",
    "label.meter": "Zähler",
    "label.measuredHotWaterConsumption": "Gemessener Warmwasserverbrauch",
    "label.heating.waterMeter": "Wärmezähler Wasseraufbereitung",
    "label.findAddMeters": "Zähler suchen und hinzufügen",
    "label.heating.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Heizung' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.cooling.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Kälte' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.water.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Warmwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.heating.groupCounterWaterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Warmwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.coolingWater.groupCounterWaterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Kaltwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.noMeters": "Keine Zähler gefunden",
    "message.successUpdateVewaConfig": "Vewa-Konfiguration erfolgreich aktualisiert",
  },
  en: {
    "detail.title": "Medium",
    "costShares.title": "Cost Share and Counter Configuration",
    "tab.label.electricity": "Electricity",
    "label.WATER_HEATING": "Hot water",
    "label.COOLING_BILL": "Cooling costs",
    "label.HEATING_BILL": "Heat costs",
    "label.WATER_BILL": "Water costs",
    "label.ROOM_HEATING": "Heating",
    "label.municipality": "Municipality",
    "label.incomingMeters": "Incoming Meters",
    "label.outgoingMeters": "Outgoing Meters",
    "label.basicCost": "Bais Cost",
    "label.consumptionBasedCost": "Consumption Based Cost",
    "label.consumptionCost": "Consumption Cost",
    "label.commercialUnits": "Commercial Units",
    "label.residentalUnits": "Residental Units",
    "label.heating": "Heating",
    "label.saveBtn": "Save Medium",
    "label.INCOMING": "Incoming",
    "label.OUTGOING": "Outgoing",
    "label.costAllocationTitle": "Is the cost breakdown correct?",
    "label.deliveryType": "Delivery Type",
    "table.header.deliveryId": "Zählpunkt-ID",
    "table.header.startDate": "Start date",
    "table.header.endDate": "End date",
    "confirmDialog.button": "Delete billing area",
    "confirmDialog.title": "Do you want to delete the billing area, including cost shares and meter configuration?",

    "label.groupMeters.HEATING_BILL": "Wärmekosten Zählerkonfiguration",
    "label.groupMeters.COOLING_BILL": "Kältekosten Zählerkonfiguration",
    "label.groupMeters.WATER_BILL": "Wasserkosten Zählerkonfiguration",
    "label.heating.meterTotalConsumption": "Wärmezähler Gesamtverbrauch",
    "label.cooling.meterTotalConsumption": "Kältezähler",
    "label.water.meterTotalConsumption": "Verteilschlüssel für verbrauchsabhängigen Anteil",
    "label.heating.meterHeating": "Wärmezähler Heizung",
    "label.meter": "Zähler",
    "label.measuredHotWaterConsumption": "Gemessener Warmwasserverbrauch",
    "label.heating.waterMeter": "Wärmezähler Wasseraufbereitung",
    "label.findAddMeters": "Zähler suchen und hinzufügen",
    "label.heating.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Heizung' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.cooling.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Kälte' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.water.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Warmwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.heating.groupCounterWaterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Warmwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.coolingWater.groupCounterWaterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Kaltwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.noMeters": "Keine Zähler gefunden",
    "message.successUpdateVewaConfig": "Vewa-Konfiguration erfolgreich aktualisiert",
  },
}
