import React, { useContext, useEffect, useState } from "react"
import { useQuery, useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash-es"
import {
  deactivateUtilityUnit,
  deleteUtilityUnit,
  getPagedMetersForUtilityUnit,
} from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { getContractsForBuilding } from "../../../../../domain/portal/admin/buildings/Buildings.Repository"
import { ConfirmDialog } from "../../../../../uikit/confirmDialog/ConfirmDialog"
import { DeactivateIcon, RemoveIcon } from "../../../../../uikit/Shared.Icon"
import { UtilityUnitResponse, UtilityUnitState } from "../../../../../data/generated-sources/openapi"
import { MasterDataContext } from "../../masterData/MasterData.Component"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"
import DateUtils from "../../../../../services/utils/DateUtils"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"

interface UnitsDataProps extends UtilityUnitResponse {
  onSuccessDelete: () => void
  onSuccessDeactivated: () => void
}

export const UtilityUnitMasterDataButtons = (props: UnitsDataProps) => {
  const { t } = useTranslation("utilityUnit")
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] = useState(false)
  const [deactivateDate, setDeactivateDate] = useState<number>(0)
  const { id: utilityUnitId, buildingId, activeState, onSuccessDeactivated, onSuccessDelete } = props
  const { setError, setComponentName, setSuccessMessage } = useContext(MasterDataContext)

  const handleSuccessUpdate = () => {
    setComponentName("utilityUnitMasterData")
    setSuccessMessage(t("utilityUnit.update.success"))
  }

  const handleErrorUpdate = (error: AxiosErrorDataType) => {
    setError(error)
    setComponentName("utilityUnitMasterData")
  }

  const { data: contracts, remove: removeBuilding } = useQuery(
    ["getBuildingContracts", buildingId],
    () => getContractsForBuilding(buildingId),
    {
      ...useQueryDefaultOptions,
      enabled: !!buildingId,
      onError: handleErrorUpdate,
    },
  )
  const { data: meters, remove: removeMeters } = useQuery(
    ["getUtilityUnitMeters", utilityUnitId],
    () => getPagedMetersForUtilityUnit(utilityUnitId, 1),
    {
      ...useQueryDefaultOptions,
      enabled: !!utilityUnitId,
      onError: handleErrorUpdate,
    },
  )
  const billableFromDates = meters?.elements?.map((meter) => meter.billableFrom).sort()
  const mostDistanDate = billableFromDates?.pop()
  const isDeactivateDisabled = isEmpty(contracts) || activeState === UtilityUnitState.INACTIVE

  useEffect(() => {
    return () => {
      removeMeters()
      removeBuilding()
    }
  }, [removeBuilding, removeMeters])

  const { mutate: deleteUnit, isLoading: isDeleteUnit } = useMutation(
    ["deleteUnit"],
    () => deleteUtilityUnit(utilityUnitId),
    {
      onSuccess: () => {
        onSuccessDelete()
        handleSuccessUpdate()
      },
      onError: handleErrorUpdate,
    },
  )

  const { mutate: deactivateUnit, isLoading: isDeactivateUnit } = useMutation(
    ["deactivateUnit"],
    () => deactivateUtilityUnit(utilityUnitId, DateUtils.getDeFormatDate(deactivateDate)),
    {
      onSuccess: () => {
        handleSuccessUpdate()
        onSuccessDeactivated()
      },
      onError: handleErrorUpdate,
    },
  )

  const showAdditionalDialogOnDeactivate = () => {
    deactivateDate && setShowDeactivateConfirmModal(true)
  }

  return (
    <>
      <ConfirmDialog
        actionButtonText={t("button.delete")}
        actionButtonStartIcon={<RemoveIcon fontSize="large" />}
        actionButtonDisabled={true}
        actionButtonLoading={isDeleteUnit || isDeactivateUnit}
        onConfirm={deleteUnit}
        dialogTitle={t("button.delete")}
      />
      <ConfirmDialog
        showPrimaryButton={false}
        openModal={showDeactivateConfirmModal}
        actionButtonLoading={isDeactivateUnit}
        onConfirm={deactivateUnit}
        dialogBodyText={t("utilityUnitDeactivate.confirmQuestion")}
      />
      <ConfirmDialog
        actionButtonText={t("button.deactivate")}
        actionButtonStartIcon={<DeactivateIcon fontSize="large" />}
        actionButtonDisabled={isDeactivateDisabled}
        dialogBody={
          <SingleLineDatePicker
            required
            name="from"
            label={t("datum.deactivate")}
            value={deactivateDate}
            onChange={setDeactivateDate}
            minDate={mostDistanDate ? new Date(mostDistanDate) : undefined}
          />
        }
        confirmButtonDisabled={!deactivateDate}
        onConfirm={showAdditionalDialogOnDeactivate}
        dialogTitle={t("button.deactivate")}
      />
    </>
  )
}
