import { Stack } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "./portal/admin/AdminPortal.Routes"
import {
  AreasBreadcrumb,
  BillingsInitialBreadcrumb,
  BillingsRecurringBreadcrumb,
  BuildingsBreadcrumb,
  ContractsBreadcrumb,
  MetersBreadcrumb,
  MyProfileBreadcrumb,
  ProductsBreadcrumb,
  ProfilesBreadcrumb,
  SettingsBreadcrumb,
  TasksBreadcrumb,
  UtilityUnitsBreadcrumb,
  ParticipantDetailsBreadcrumb,
  TariffsBreadcrumb,
} from "./portal/admin/breadcrumbs"
import { newDesignSelectColorDark } from "./Shared.Theme"

export const AppBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  return (
    <Stack
      flex={1}
      component={"nav"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      sx={{ maxWidth: "calc(100% - 320px)", color: newDesignSelectColorDark, fontSize: "20px" }}
    >
      <AreasBreadcrumb {...props} />
      <BillingsInitialBreadcrumb {...props} />
      <BillingsRecurringBreadcrumb {...props} />
      <BuildingsBreadcrumb {...props} />
      <ContractsBreadcrumb {...props} />
      <MetersBreadcrumb {...props} />
      <MyProfileBreadcrumb {...props} />
      <ParticipantDetailsBreadcrumb {...props} />
      <ProductsBreadcrumb {...props} />
      <ProfilesBreadcrumb {...props} />
      <SettingsBreadcrumb {...props} />
      <TasksBreadcrumb {...props} />
      <UtilityUnitsBreadcrumb {...props} />
      <TariffsBreadcrumb {...props} />
    </Stack>
  )
}
