import { push } from "connected-react-router"
import { Dispatch } from "react"
import { connect } from "react-redux"
import { AnyAction } from "redux"
import { AppState } from "../../../App.Reducer"
import { BuildingsActionType } from "./Buildings.Epic"
import { BuildingsDetailComponent } from "./BuildingsDetail.Component"

const mapStateToProps = (state: AppState) => {
  return {
    ...state.adminPortal.buildingsDetail,
  }
}

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    deactivateBuilding: (buildingId: string, fromDate: number) => {
      dispatch({
        type: BuildingsActionType.BUILDINGS_DEACTIVATE,
        buildingId,
        fromDate,
      })
    },
    deleteBuilding: (buildingId: string) => {
      dispatch({
        type: BuildingsActionType.BUILDINGS_DELETE,
        buildingId,
      })
    },
    navigateToUpdateBuilding: (buildingId: string) => {
      dispatch(push(`/buildings/${buildingId}/update`))
    },
    navigateToCreate: (buildingId: string) => {
      dispatch(push(`/buildings/${buildingId}/utility-unit/create`))
    },
    navigateToMeterCreate: (buildingId: string) => {
      dispatch(push(`/buildings/${buildingId}/meters/create`))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingsDetailComponent)
