import { IconButton, Stack, Typography, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { formatMoneyDecimalLabel } from "../../../../domain/Domain.Formatters"
import { EditIcon } from "../../../../uikit/Shared.Icon"
import { ZevPricePackage } from "../../../../data/generated-sources/openapi"

const PackageTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: "#3C403C",
}))

export const ElectricityPricePackageView = ({
  pricePackage,
  onSetEditMode,
}: {
  pricePackage: ZevPricePackage
  onSetEditMode: (pricePackageId: number) => void
}) => {
  const { t } = useTranslation("contracts-management")
  const { name, prices } = pricePackage

  return (
    <>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} mb={6}>
        <Typography fontWeight={700}>{name}</Typography>
        <IconButton onClick={() => onSetEditMode(pricePackage.id)} size="small" color="primary">
          <EditIcon />
        </IconButton>
      </Stack>

      <PackageTypography variant={"subtitle1"}>{t("priceForm.solarPrice")}</PackageTypography>
      <PackageTypography mb={4}>{formatMoneyDecimalLabel(prices?.solarPrice)}</PackageTypography>

      <PackageTypography variant={"subtitle1"}>{t("priceForm.highTariffPrice")}</PackageTypography>
      <PackageTypography mb={4}>{formatMoneyDecimalLabel(prices?.highTariffPrice)}</PackageTypography>

      <PackageTypography variant={"subtitle1"}>{t("priceForm.lowTariffPrice")}</PackageTypography>
      <PackageTypography mb={4}>{formatMoneyDecimalLabel(prices?.lowTariffPrice)}</PackageTypography>

      <PackageTypography variant={"subtitle1"}>
        {prices?.isFixedRate ? t("priceForm.monthlyMeasurement") : t("priceForm.kwhMeasurement")}
      </PackageTypography>
      <PackageTypography mb={4}>{formatMoneyDecimalLabel(prices?.additionalServicesPrice)}</PackageTypography>

      <PackageTypography variant={"subtitle1"}>{t("priceForm.spikePrice")}</PackageTypography>
      <PackageTypography>{prices?.spikePrice ? formatMoneyDecimalLabel(prices?.spikePrice) : "-"}</PackageTypography>
    </>
  )
}
