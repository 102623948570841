import { Map } from "immutable"

import {
  ContractAdminResponse,
  ServiceBillingAdminResponse,
  ServiceBillingPosition,
  ServiceBillingType,
} from "../../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../Domain.Model"

export enum ServiceBillingActionState {
  APPROVE = "approve",
  CANCEL = "cancel",
  DELETE = "delete",
}

export enum AccountingStatus {
  CREATED = "CREATED",
  UNAVAILABLE = "UNAVAILABLE",
  ERROR = "ERROR",
}

export enum ServiceBillingState {
  DRAFT = "DRAFT",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

export interface BillingsRecurringList {
  billingsRecurring: BillingsRecurringListItem[]
}

export interface BillingsRecurringListItem {
  id: string
  statusType: StatusType
  sortableStatusType: string
  period: string
  filterablePeriod: string
  sortablePeriod: number
  zevs: {
    id: string
    name: string
  }[]
}

export interface BillingsRecurringDetail {
  id: string
  statusType: StatusType
  period: string
  serviceBillings: ServiceBilling[]
  contractConfigIds: string[]
}

export interface ServiceBilling {
  id: string
  statusType: StatusType
  sortableStatusType: string
  period: string
  sortablePeriod: number
  positions: BillingPosition[]
  billingType: string
  // zevId: string
  // zevName: string
  contractId: string
  contractName: string
  totalAmountDue: string
  sortableTotalAmountDue: number
  accountingStatus: AccountingStatus
  sortableAccountingStatus: string
  invoiceReferenceNumber: string
  orderReferenceNumber: string
  submissionError?: string
}

export type ServiceBillingMap = Map<string, ServiceBillingAdmin["accountingStatus"]>
export interface ServiceBillingAdmin {
  id: string
  billingDate: string
  activeState: ServiceBillingState
  startDate?: string
  endDate?: string
  positions: ServiceBillingPosition[]
  billingType: ServiceBillingType
  contractId: string
  billingRunId?: string
  totalAmountDue: string
  accountingStatus: AccountingStatus
  orderReferenceNumber?: string
  invoiceReferenceNumber?: string
  accountingErrorMessage?: string
  contractConfigId: string
}

export interface BillingPosition {
  id: string
  name: string
  price: string
  sortablePrice: number
  quantity: string
  sortableQuantity: number
  finalAmountDue: string
  sortableFinalAmountDue: number
}

export interface BillingRecurringZevListItem {
  id: string
  name: string
  zevStartDate: number
  serviceEndDate: number
}

export enum HalfOfYear {
  FIRST = "FIRST",
  SECOND = "SECOND",
}

export interface BillingsRecurringUpsert {
  year: Date
  halfOfYear: HalfOfYear
  startDate: Date
  endDate: Date
  selectedContract: Array<string>
}

export const emptyBillingsRecurringCreate = {
  year: new Date(),
  halfOfYear: HalfOfYear.FIRST,
  startDate: new Date(`${new Date().getFullYear()}-01-01`),
  endDate: new Date(`${new Date().getFullYear()}-06-30`),
  selectedContract: [],
}

export interface ContractServiceBillingInterface {
  contractResponse: ContractAdminResponse
  serviceBillingAdminResponse: ServiceBillingAdminResponse
}
