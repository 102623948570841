import { t } from "i18next"
import { PagedProfileAdminResponse, ProfileAdminResponse, UserType } from "../../../../data/generated-sources/openapi"
import { DomainDependencies } from "../../../Domain.Dependencies"
import { formatPersonalFullName, formatTitleSalutation } from "./Profiles.Formatters"
import {
  ProfileDetail,
  ProfileListItem,
  ProfileManagerNameListItem,
  ProfileStatusType,
  ProfileUpsert,
} from "./Profiles.Model"
import { StatusType } from "../../../Domain.Model"
import { ORDERED_STRING_COMPARATOR } from "../../../Domain.Comparators"

export const mapManagersName = (
  managers: ProfileAdminResponse[],
  deps: DomainDependencies,
): ProfileManagerNameListItem[] => {
  return managers
    .filter((profile: ProfileAdminResponse) => profile.userType === UserType.ZEV_MANAGER && !profile.isBlocked)
    .map((profile) => ({
      id: profile.id,
      fullNameAddress:
        `${formatPersonalFullName(profile.personal, deps)}, ${profile.address.street} ` +
        `${profile.address.houseNumber}`,
    }))
    .sort((a, b) => ORDERED_STRING_COMPARATOR(a.fullNameAddress, b.fullNameAddress, "asc"))
}

export const managerNamesMapper = (
  allProfilesResponse: PagedProfileAdminResponse,
  deps: DomainDependencies,
): ProfileManagerNameListItem[] => {
  return mapManagersName(allProfilesResponse.elements, deps)
}

export const profileListItemMapper = (profile: ProfileAdminResponse, deps: DomainDependencies): ProfileListItem => {
  return {
    id: profile.id,
    profileName: formatPersonalFullName(profile.personal, deps),
    userName: profile.username,
    type: profile.userType,
    profileStatusType: profileStatusTypeMapper(profile.username, profile.isBlocked),
    sortableStatusType: t(
      `profiles:status.${ProfileStatusType[profileStatusTypeMapper(profile.username, profile.isBlocked)]}`,
    ),
  }
}

export const profileDetailMapper = (response: ProfileAdminResponse, deps: DomainDependencies): ProfileDetail => {
  return {
    id: response.id,
    statusType: StatusType[response.activeState],
    username: response.username,
    profileName: `${response.personal.firstName} ${response.personal.lastName}`,
    userTitle: `${formatTitleSalutation(response.personal.title, response.personal.salutation, deps)}`,
    address: `${response.address.street} ${response.address.houseNumber}`,
    city: `${response.address.postalCode} ${response.address.city}`,
    telephone: response.contact.telephone ?? "",
    mobile: response.contact.mobile ?? "",
    email: response.contact.email ?? "",
    type: response.userType,
    profileStatusType: profileStatusTypeMapper(response.username, response.isBlocked),
  }
}

export const profileStatusTypeMapper = (username: string, isBlocked: boolean) => {
  if (username === "unavailable") return ProfileStatusType.CREATED
  if (isBlocked) return ProfileStatusType.DEACTIVATED
  return ProfileStatusType.ACTIVE
}

export const profileUpdateMapper = (response: ProfileAdminResponse): ProfileUpsert => {
  return {
    statusType: StatusType[response.activeState],
    userType: response.userType,
    firstName: response.personal.firstName,
    lastName: response.personal.lastName,
    title: response.personal.title ?? "",
    salutation: response.personal.salutation,
    houseNumber: response.address.houseNumber,
    street: response.address.street,
    postalCode: response.address.postalCode,
    city: response.address.city,
    telephone: response.contact.telephone ?? "",
    mobile: response.contact.mobile ?? "",
    email: response.contact.email ?? "",
  }
}
