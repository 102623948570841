import React from "react"
import { DatePickerView } from "@mui/lab/DatePicker/shared"
import { SxProps, TextField } from "@mui/material"
import { ElementType, useState } from "react"
import { Theme } from "@emotion/react"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab"
import { de } from "date-fns/locale"

export enum DatePickerMonthDay {
  CURRENT = "CURRENT",
  BEGIN = "BEGIN",
  END = "END",
}

interface SingleLineDatePickerProps {
  id?: string
  name: string
  label: string
  value: number
  required?: boolean
  disabled?: boolean
  helperText?: string
  onChange: (date: number) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  minDate?: Date
  maxDate?: Date
  views?: DatePickerView[]
  range?: DatePickerMonthDay
  openPickerIcon?: ElementType
  textInputStyle?: SxProps<Theme>
}

export const SingleLineDatePicker = (props: SingleLineDatePickerProps) => {
  const {
    name,
    label,
    value,
    required,
    disabled,
    helperText,
    onChange,
    onBlur,
    minDate,
    maxDate,
    views = ["year", "month", "day"],
    range = DatePickerMonthDay.CURRENT,
    openPickerIcon,
    textInputStyle,
  } = props

  const [open, setOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
      <DesktopDatePicker<Date>
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={label}
        disabled={disabled}
        value={value !== -1 && value !== 0 ? new Date(value) : null}
        onChange={(date) => {
          if (date === null) {
            onChange(-1)
          } else if (views.includes("month")) {
            switch (range) {
              case DatePickerMonthDay.CURRENT:
                onChange(date.getTime())
                break
              case DatePickerMonthDay.BEGIN:
                onChange(new Date(date.getFullYear(), date.getMonth(), 1).getTime())
                break
              case DatePickerMonthDay.END:
                onChange(new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime())
                break
            }
          } else {
            onChange(date.getTime())
          }
        }}
        components={{ OpenPickerIcon: openPickerIcon }}
        minDate={minDate}
        maxDate={maxDate}
        defaultCalendarMonth={minDate}
        views={views}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        renderInput={(props) => (
          <TextField
            {...props}
            sx={{
              width: "100%",
              ...textInputStyle,
            }}
            name={name}
            helperText={helperText}
            error={helperText !== undefined}
            label={label}
            required={required}
            variant="standard"
            onFocus={(e) => {
              setTimeout(() => {
                e.target?.select()
              }, 100)
            }}
            onClick={() => setOpen(true)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setOpen(false)
              }
            }}
            onBlur={onBlur}
            autoComplete="off"
          />
        )}
      />
    </LocalizationProvider>
  )
}
