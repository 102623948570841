import { useEffect, useState } from "react"
import { PrimaryTab, PrimaryTabContainer } from "../../../../uikit/tabs/PrimaryTab"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import TabPanel from "../utilityUnit/UtilityUnitDetail"
import { SettingsElectricityTab } from "./components/electricity/SettingsElectricityTab"
import { ProductType } from "../../../../data/generated-sources/openapi"
import FeesWrapper from "./components/fees/FeesWrapper"
import MyZevElectricityCalculatorConnect from "../my-zev/MyZevElectricityCalculator.Connect"
import { BillingSettingsTab } from "./components/billingPreferences/BillingSettingsTab"
import { useAppSelector } from "../../../hooks"
import { selectManagerSettingActiveTab } from "../store/uiSlice"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import ContractSelector from "../contractSelector/ContractSelector"
import { ManagerSettingsTabsModels } from "./ManagerSettingsModels"
import ProductHistory from "../../shared/productHistory/ProductHistory"
import { SettingsVewaCostSharesTab } from "./components/vewaCostShares/SettingsVewaCostSharesTab"

export const ManagerSettings = () => {
  const { t } = useTranslation("settings")
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const [selectedTab, setSelectedTab] = useState(0)
  const activeTab = useAppSelector(selectManagerSettingActiveTab)

  useEffect(() => {
    setSelectedTab(activeTab)
  }, [activeTab])

  const zevTabs: ManagerSettingsTabsModels[] = [
    {
      name: "billings",
      label: t("tab.billings"),
    },
    {
      name: "fee",
      label: t("tab.fees"),
    },
    {
      name: "electricity",
      label: t("tab.electricity"),
    },
    {
      name: "price-calculator",
      label: t("tab.priceCalculator"),
    },
    {
      name: "product-history",
      label: t("tab.productHistory"),
    },
  ]

  const vewaTabs: ManagerSettingsTabsModels[] = [
    {
      name: "billings",
      label: t("tab.billings"),
    },
    {
      name: "fee",
      label: t("tab.fees"),
    },
    {
      name: "cost-sharing",
      label: t("tab.costSharing"),
    },
    {
      name: "product-history",
      label: t("tab.productHistory"),
    },
  ]

  const renderTabs = () => {
    const correctTabList = contractProductType === ProductType.ZEV ? zevTabs : vewaTabs

    return correctTabList.map((item: ManagerSettingsTabsModels) => <PrimaryTab label={item.label} key={item.name} />)
  }

  const primaryTabsLayout = () => {
    return (
      <PrimaryTabContainer value={selectedTab} onChange={(_, tab) => setSelectedTab(tab)}>
        {renderTabs()}
      </PrimaryTabContainer>
    )
  }

  return (
    <Box>
      <ContractSelector />
      {primaryTabsLayout()}
      <TabPanel value={selectedTab} index={0}>
        <BillingSettingsTab contractId={contractId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <FeesWrapper contractId={contractId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {contractProductType === ProductType.ZEV ? (
          <SettingsElectricityTab contractId={contractId} />
        ) : (
          <SettingsVewaCostSharesTab />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <MyZevElectricityCalculatorConnect contractId={contractId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <Box p={3}>
          <ProductHistory contractId={contractId} />
        </Box>
      </TabPanel>
    </Box>
  )
}
