import { Observable, from, map, mergeMap, forkJoin, of } from "rxjs"
import { apiHeaders, apiCall } from "../../../../Domain.Calls"
import { DomainDependencies } from "../../../../Domain.Dependencies"
import { DomainResponse } from "../../../../Domain.Response"
import {
  BillingsRecurringDetail,
  BillingsRecurringUpsert,
  ContractServiceBillingInterface,
  ServiceBillingActionState,
} from "./BillingsRecurring.Model"
import {
  MultiServiceBillingAdminResponse,
  ServiceBillingAdminResponse,
  ServiceBillingRunMultiAdminResponse,
} from "../../../../../data/generated-sources/openapi"
import { AxiosResponse } from "axios"
import { timestampToApiFormattedDate } from "../../../../Domain.Formatters"
import { billingsRecurringMapper } from "./BillingsRecurring.Mapper"

export const getRecurringBillingRuns = (
  deps: DomainDependencies,
): Observable<DomainResponse<ServiceBillingRunMultiAdminResponse>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminServiceBillingsApi.getAllAdminServiceBillingRuns(headers)).pipe(
      map(
        (serviceBillingsResponse: AxiosResponse<ServiceBillingRunMultiAdminResponse>) => serviceBillingsResponse.data,
      ),
    ),
  )
}
export const getRecurringBillingRunById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<BillingsRecurringDetail>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminServiceBillingsApi.getAdminServiceBillingRunById(billingId, headers)).pipe(
      mergeMap((billingRunResponse) =>
        getServiceBillingsForBillingRun(billingRunResponse.data.serviceBillingIds, deps).pipe(
          map((values) => billingsRecurringMapper(billingRunResponse.data, values, deps)),
        ),
      ),
    ),
  )
}

export const getRecurringBillings = (
  deps: DomainDependencies,
): Observable<DomainResponse<ServiceBillingAdminResponse[]>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminServiceBillingsApi.getAdminServiceBillings(headers)).pipe(
      map(
        (serviceBillingsResponse: AxiosResponse<MultiServiceBillingAdminResponse>) =>
          serviceBillingsResponse.data.elements,
      ),
    ),
  )
}

export const getServiceBillingsForBillingRun = (
  serviceBillingIds: string[],
  deps: DomainDependencies,
): Observable<ContractServiceBillingInterface[]> => {
  if (!serviceBillingIds.length) return of([])
  return forkJoin(
    serviceBillingIds?.map((serviceBillingId) =>
      from(deps.adminServiceBillingsApi.getAdminServiceBillingById(serviceBillingId, apiHeaders(deps))).pipe(
        mergeMap((serviceBillingAdminResponse) =>
          from(
            deps.adminContractApi.adminGetContractById(serviceBillingAdminResponse.data.contractId, apiHeaders(deps)),
          ).pipe(
            map((response) => ({
              contractResponse: response.data,
              serviceBillingAdminResponse: serviceBillingAdminResponse.data,
            })),
          ),
        ),
      ),
    ),
  )
}

export const createRecurringBillings = (
  recurringBillingUpsert: BillingsRecurringUpsert,
  deps: DomainDependencies,
): Observable<DomainResponse<string>> => {
  return apiCall(
    from(
      deps.adminServiceBillingsApi.createAdminServiceBillingRun(
        {
          contractIds: recurringBillingUpsert.selectedContract,
          startDate: timestampToApiFormattedDate(recurringBillingUpsert.startDate.getTime(), deps),
          endDate: timestampToApiFormattedDate(recurringBillingUpsert.endDate.getTime(), deps),
        },
        apiHeaders(deps),
      ),
    ).pipe(map((url) => url.data.split("/").pop() ?? "")),
  )
}

export const approveServiceBillingsRunById = (
  billingRunId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.approveAdminServiceBillingRunById(billingRunId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const sapSendServiceBillingsRunById = (
  billingRunId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.sapSendAdminServiceBillingRunById(billingRunId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const sapSendServiceBillingsById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.sapSendAdminServiceBillingById(billingId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const recalculateServiceBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.recalculateAdminServiceBillingById(billingId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const cancelServiceBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.adminServiceBillingsApi.putAdminServiceBillingStateChangeById(
        billingId,
        ServiceBillingActionState.CANCEL,
        apiHeaders(deps),
      ),
    ).pipe(map(() => true)),
  )
}

export const deleteRecurringBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.deleteAdminServiceBillingRunById(billingId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const removeServiceBillingRunContractById = (
  billingRunId: string,
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.adminServiceBillingsApi.removeAdminServiceBillingRunContractById(billingRunId, contractId, apiHeaders(deps)),
    ).pipe(map(() => true)),
  )
}
