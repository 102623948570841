import { StatusType } from "../../../Domain.Model"

export interface BuildingsList {
  buildings: BuildingListItem[]
}

export interface BuildingListItem {
  id: string
  statusType: StatusType
  sortableStatusType: string
  buildingObject: string
  address: string
  areaId: string
  areaName: string
}

export interface BuildingUpsert {
  statusType: StatusType
  buildingObject: string
  addressStreet: string
  addressHouseNumber: string
  addressPostCode: string
  addressCity: string
}

export const initialBuilding = {
  statusType: StatusType.DRAFT,
  buildingObject: "",
  addressStreet: "",
  addressHouseNumber: "",
  addressPostCode: "",
  addressCity: "",
}
