import {
  AllParticipantBillingPricesUpdateCustomerRequest,
  AllParticipantsBillingState,
  BillDetails,
  BillingDateRequest,
  IndividualParticipantBillingPricesUpdateRequest,
  IndividualParticipantBillingState,
  VewaCostShares,
  ZevPricePackage,
} from "../../../../data/generated-sources/openapi"
import { apiHeaders } from "../../../Domain.Calls"

import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import {
  setIndividualBillingsData,
  setVewaPeriodicBillingsData,
  setZevPeriodicBillingsData,
} from "../../../../app/portal/manager/store/billingsSlice"
import { AppDispatch } from "../../../../app/App.Store"
import {
  individualParticipantBillingMapper,
  vewaPeriodicBillingMapper,
  zevPeriodicBillingMapper,
} from "./Billings.Mapper"
import { ZevAllParticipantBilling, ZevIndividualParticipantBilling } from "./Billings.Model"
import { ProductName } from "../../admin/billings/participant/BillingsParticipant.Model"

export const customerGetZevAllParticipantsBillingsByContractId = async (
  dispatch: AppDispatch,
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data: periodicBillsForZev } =
    await DOMAIN_DEPENDENCIES.customerContractApi.customerGetPageAllParticipantsBillingsContractById(
      contractId,
      page,
      limit,
      orderBy,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const { zevPeriodicBillings: periodicParticipantBillings } = zevPeriodicBillingMapper(periodicBillsForZev)

  dispatch(setVewaPeriodicBillingsData([]))
  dispatch(setZevPeriodicBillingsData(periodicParticipantBillings))

  return periodicParticipantBillings
}

export const customerGetPagedVewaAllParticipantsBillingsByContractId = async (
  dispatch: AppDispatch,
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data: periodicVewaBills } =
    await DOMAIN_DEPENDENCIES.customerContractApi.customerGetPagedVewaAllParticipantsBillingsByContractId(
      contractId,
      page,
      limit,
      orderBy,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const { data: contractResponse } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  const { vewaPeriodicBillings: vewaPeriodicParticipantBillings } = vewaPeriodicBillingMapper(
    periodicVewaBills,
    contractResponse,
  )

  dispatch(setZevPeriodicBillingsData([]))
  dispatch(setVewaPeriodicBillingsData(vewaPeriodicParticipantBillings))

  return vewaPeriodicParticipantBillings
}

export const customerGetPageIndividualParticipantsBillingsByContractId = async (
  dispatch: AppDispatch,
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data: individualParticipantsBillings } =
    await DOMAIN_DEPENDENCIES.customerContractApi.customerGetPageIndividualParticipantsBillingsByContractId(
      contractId,
      page,
      limit,
      orderBy,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const { individualParticipantBillings } = individualParticipantBillingMapper(
    individualParticipantsBillings,
    DOMAIN_DEPENDENCIES,
  )

  dispatch(setIndividualBillingsData(individualParticipantBillings))

  return individualParticipantBillings
}

export const customerGetVewaAllParticipantBillingDetailsById = async (billId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerGetVewaAllParticipantBillingById(
    billId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetVewaAllParticipantBillingParticipants = async (
  billId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } =
    await DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerGetVewaIndividualParticipationConsumption(
      billId,
      page,
      limit,
      orderBy,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )
  return data
}

export const customerUpdateBillingCostShares = async (
  vewaAllParticipantBillingId: string,
  vewaCostShares: VewaCostShares,
) =>
  DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerUpdateBillingCostShares(
    vewaAllParticipantBillingId,
    vewaCostShares,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerGetProductName = async (contractId: string): Promise<ProductName | undefined> => {
  const { data: contractData } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  let productName = undefined

  if (contractData?.product?.name?.toLowerCase()?.includes(ProductName.ZEV_COMFORT.toLowerCase())) {
    productName = ProductName.ZEV_COMFORT
    return productName
  }
  if (contractData?.product?.name?.toLowerCase()?.includes(ProductName.ZEV_PREMIUM_PLUS.toLowerCase())) {
    productName = ProductName.ZEV_PREMIUM_PLUS
    return productName
  }
  if (contractData?.product?.name?.toLowerCase()?.includes(ProductName.ZEV_PREMIUM.toLowerCase())) {
    productName = ProductName.ZEV_PREMIUM
    return productName
  }
  return productName
}

export const customerAllParticipantBillingDetailsById = async (
  billingId: string,
  contractId: string,
): Promise<ZevAllParticipantBilling> => {
  const { data: billing } =
    await DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.getCustomerAllParticipantBillingDetailsById(
      billingId,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const productName = await customerGetProductName(contractId)

  // canCreateBilling: boolean
  // logic from old ZevMapper
  const utilityUnitIds = billing.bills.map((pm: BillDetails) => pm.utilityUnitId)
  const utilityUnitData = billing.bills.map((bill) => bill.utilityUnit)
  let canCreateBilling = true

  if (!billing || !billing.bills.length || !utilityUnitIds.length) {
    canCreateBilling = false
  }

  if (
    !utilityUnitIds.some(
      (utilityUnitId: string) => !!utilityUnitData.filter((cp) => cp?.id === utilityUnitId)[0]?.participations?.length,
    )
  ) {
    canCreateBilling = false
  }

  const isBillingSuspended = billing.billingStatus === AllParticipantsBillingState.SUSPENDED

  return { ...billing, canCreateBilling, isBillingSuspended, productName }
}

export const customerIndividualParticipantBillingDetailsById = async (
  billingId: string,
  contractId: string,
): Promise<ZevIndividualParticipantBilling> => {
  const { data: billing } =
    // eslint-disable-next-line max-len
    await DOMAIN_DEPENDENCIES.customerIndividualParticipantBillingApi.getCustomerIndividualParticipantBillingDetailsById(
      billingId,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const productName = await customerGetProductName(contractId)

  // canCreateBilling: boolean
  // logic from old ZevMapper
  const utilityUnitIds = [billing.bill.utilityUnitId]
  let canCreateBilling = true

  if (!billing || !billing.bill || !billing.bill.utilityUnitId) {
    canCreateBilling = false
  }
  if (
    !utilityUnitIds.every(
      (consumptionPointsId: string) =>
        !![billing.bill.utilityUnit].filter((cp) => cp?.id === consumptionPointsId)[0]?.participations?.length,
    )
  ) {
    canCreateBilling = false
  }

  const billPricePackage: ZevPricePackage[] = [
    {
      id: 0,
      name: "-",
      order: 0,
      prices: billing.prices,
    },
  ]

  const isBillingSuspended = billing.billingStatus === IndividualParticipantBillingState.SUSPENDED

  return {
    ...billing,
    productName,
    canCreateBilling,
    bills: [billing.bill],
    pricePackages: billPricePackage,
    isBillingSuspended,
  }
}

export const customerApproveAllParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.putCustomerAllParticipantBillingApproveStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerApproveIndividualParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.customerIndividualParticipantBillingApi.putCustomerIndividualParticipantBillingApproveStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const updateCustomerAllParticipantBillingPricesById = async (
  billingId: string,
  allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateCustomerRequest,
) =>
  DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.updateCustomerAllParticipantBillingPricesById(
    billingId,
    allParticipantBillingPricesUpdateCustomerRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerUpdateIndividualParticipantBillingPricesById = async (
  billingId: string,
  individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest,
) => {
  // eslint-disable-next-line max-len
  return DOMAIN_DEPENDENCIES.customerIndividualParticipantBillingApi.updateCustomerIndividualParticipantBillingPricesById(
    billingId,
    individualParticipantBillingPricesUpdateRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerReopenAllParticipantBilling = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.putCustomerAllParticipantBillingReopenStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerReopenIndividualParticipantBilling = async (billingId: string) => {
  // eslint-disable-next-line max-len
  return DOMAIN_DEPENDENCIES.customerIndividualParticipantBillingApi.putCustomerIndividualParticipantBillingReopenStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerReopenVewaParticipantDoneBilling = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerReopenVewaParticipantBilling(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerReloadVewaParticipantBillingData = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerReloadVewaParticipantBillingData(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerReloadZevParticipantBillingData = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerReloadZevParticipantBillingData(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerDeleteVewaBillingDataAndResetNextBillingDate = async (
  vewaAllParticipantBillingId: string,
  nextBillingDate: BillingDateRequest,
) => {
  return DOMAIN_DEPENDENCIES.customerAllParticipantBillingApi.customerDeleteVewaBillingDataAndResetNextBillingDate(
    vewaAllParticipantBillingId,
    nextBillingDate,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}
