import { Form, Formik } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { BuildingUpsert } from "../../../../domain/portal/admin/buildings/Buildings.Model"
import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"
import { FormMode } from "../../../../uikit/form/FormView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { firstViewState } from "../../../Shared.Reducer"
import { coerce } from "../../../Shared.View"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { mapDispatchToProps } from "./BuildingsUpdate.Connect"
import { BuildingsUpdateState } from "./BuildingsUpdate.Reducer"
import { BuildingsForm } from "./form/BuildingsForm"
import { validateBuilding } from "./form/BuildingsForm.Validation"

interface BuildingsUpdateComponentProps
  extends BuildingsUpdateState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const BuildingsUpdateComponent = (props: BuildingsUpdateComponentProps) => {
  const { t } = useTranslation("buildings")
  const { getViewState, getBuildingUpsert, updateViewState, updateBuilding, match, navigateToBuilding } = props
  useEffect(() => {
    if (firstViewState(getViewState)) {
      getBuildingUpsert(match.params.buildingId)
    }
  }, [getViewState, match.params?.buildingId, getBuildingUpsert])
  if (getViewState.domainError) return <ErrorAlert message={getViewState.domainError.message} />
  if (updateViewState.domainResult) return <Redirect to={`/buildings/${match.params.buildingId}`} />
  return (
    <>
      {coerce(getViewState.domainResult, (buildingUpsert) => (
        <>
          <Formik<BuildingUpsert>
            initialValues={buildingUpsert}
            onSubmit={(values) => updateBuilding(match.params.buildingId, values)}
            validate={(values) => validateBuilding(values, t)}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty }) => (
              <Form onSubmit={handleSubmit}>
                {updateViewState.domainError && <ErrorAlert message={updateViewState.domainError.message} />}
                <BuildingsForm
                  mode={FormMode.UPDATE}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <FormUpdateActionsView
                  buttonCtaLabel={t("update.form.action.cta")}
                  isValid={isValid}
                  dirty={dirty}
                  isLoading={updateViewState.isLoading}
                  navigateBack={() => navigateToBuilding(match.params.buildingId)}
                />
              </Form>
            )}
          </Formik>
        </>
      ))}
    </>
  )
}
