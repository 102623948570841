import { Paper, Typography, Stack, Box } from "@mui/material"
import { FabButton } from "../../../../../uikit/button"
import { EditIcon } from "../../../../../uikit/Shared.Icon"
import { useTranslation } from "react-i18next"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import { useHistory, useParams } from "react-router-dom"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

export const ContractComment = ({ comment }: ContractAdminResponse) => {
  const { id: contractId } = useParams<AdminPortalRouteParams>()
  const { t } = useTranslation("shared")
  const history = useHistory()

  const goToEditNotes = () => history.push(`/management-contracts/${contractId}/comment/update`)

  return (
    <Paper sx={{ pt: 5 }} data-testid="ContractCommentSection">
      <Stack flexDirection={"row"} alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h5" fontWeight={300} textTransform={"uppercase"} data-testid="ContractCommentHeader">
          {t("shared:label.comments")}
        </Typography>
        <FabButton size="medium" icon={<EditIcon />} onClick={goToEditNotes} data-testid="ContractCommentEditButton" />
      </Stack>
      <Box py={2} pl={1} pr={10}>
        <Typography fontSize={16} fontWeight={400} data-testid="ContractCommentText">
          {comment ?? "-"}
        </Typography>
      </Box>
    </Paper>
  )
}
