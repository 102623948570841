import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import TableCell from "@mui/material/TableCell"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../hooks"
import { AppState } from "../../../../App.Reducer"
import { BuildingListItem } from "../../../../../domain/portal/admin/buildings/Buildings.Model"
import { OpenButton } from "../../../../../uikit/button/OpenButton"
import { Loader } from "../../../../../uikit/indicator/ProgressIndicator"
import { UtilityUnitResponse } from "../../../../../data/generated-sources/openapi"
import { getParticipantFullName } from "../../../../../services/utils/ParticipantUtils"
import { UtilityUnitParticipationParticipant } from "../../../../../domain/participant/Participant.Model"

interface UtilityUnitResponseExtended extends UtilityUnitResponse {
  participationParticipants?: UtilityUnitParticipationParticipant[]
}

interface UtilityUnitTableInterface {
  itemConfig: UtilityUnitResponseExtended
  showBuildingLayout: boolean
}

const UtilityUnitTableComponent = (props: UtilityUnitTableInterface) => {
  const history = useHistory()
  const { t } = useTranslation("utilityUnit")
  const { itemConfig, showBuildingLayout } = props
  const [foundBuilding, setFoundBuilding] = useState<BuildingListItem>()
  const buildings = useAppSelector(
    (state: AppState) => state?.adminPortal?.buildingsList?.viewState?.domainResult?.buildings,
  )

  useEffect(() => {
    const building = buildings?.find((item: BuildingListItem) => item.id === itemConfig.buildingId)
    setFoundBuilding(building)
  }, [buildings, itemConfig.buildingId])

  const buildingLayout = () => {
    if (!foundBuilding?.id) {
      return <Loader height={30} justify="start" align="center" size={24} />
    }
    return <OpenButton label={foundBuilding.areaName} open={() => history.push(`/buildgings/${foundBuilding?.id}`)} />
  }

  const participantLayout = () => {
    const currentParticipant = itemConfig.participationObjects?.find((participant) => participant.isCurrent)

    if (!currentParticipant?.participant?.personalData) return <>-</>
    return getParticipantFullName(currentParticipant?.participant?.personalData)
  }

  return (
    <TableRowClickable<UtilityUnitResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(metersItem) => {
        if (metersItem.id) {
          history.push(`/utility-units/${itemConfig.id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name}</TableCell>
      <TableCell align="left">{t(`utilityType.${itemConfig.usageType}`)}</TableCell>
      <TableCell align="left">{participantLayout()}</TableCell>
      {showBuildingLayout && <TableCell align="left">{buildingLayout()}</TableCell>}
    </TableRowClickable>
  )
}

export default UtilityUnitTableComponent
