import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { BillingIcon } from "../../../../uikit/Shared.Icon"

export const BillingsRecurringBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("billings-recurring")
  const { history } = props

  const breadcrumbHomeIcon = () => (
    <BreadcrumbsHomeIcon redirectUrl={() => history.push("/billings/recurring")} icon={<BillingIcon />} />
  )

  return (
    <Switch>
      <Route path="/billings/recurring/create">
        {breadcrumbHomeIcon()}
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("create.title")} />
      </Route>

      <Route path="/billings/recurring/:billingId">
        {breadcrumbHomeIcon()}
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("detail.title")} />
      </Route>

      <Route path="/billings/recurring">
        {breadcrumbHomeIcon()}
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("billingType.RECURRING")} />
      </Route>
    </Switch>
  )
}
