import { Form, Formik, FormikProps } from "formik"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { BuildingUpsert, initialBuilding } from "../../../../domain/portal/admin/buildings/Buildings.Model"
import { ClearForm } from "../../../../uikit/form/ClearForm"
import { FormMode } from "../../../../uikit/form/FormView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { PlusIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { mapDispatchToProps } from "./BuildingsCreate.Connect"
import { BuildingsCreateState } from "./BuildingsCreate.Reducer"
import { BuildingsForm } from "./form/BuildingsForm"
import { validateBuilding } from "./form/BuildingsForm.Validation"
import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"

interface BuildingsCreateComponentProps
  extends BuildingsCreateState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const BuildingsCreateComponent = (props: BuildingsCreateComponentProps) => {
  const { t } = useTranslation("buildings")
  const { createBuilding, createViewState, match, history, navigateToArea } = props
  const formRef = useRef<FormikProps<BuildingUpsert>>(null)

  useEffect(() => {
    if (createViewState.domainResult) {
      navigateToArea(match.params.id)
    }
  }, [match.params.id, createViewState.domainResult, navigateToArea])

  return (
    <>
      <ClearForm<BuildingUpsert> viewState={createViewState} formRef={formRef} />
      <Formik<BuildingUpsert>
        initialValues={initialBuilding}
        onSubmit={(values) => createBuilding(match.params.id, values)}
        validate={(values) => validateBuilding(values, t)}
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty }) => (
          <Form onSubmit={handleSubmit}>
            {createViewState.domainError && <ErrorAlert message={createViewState.domainError.message} />}
            <BuildingsForm
              mode={FormMode.CREATE}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <FormUpdateActionsView
              dirty={dirty}
              isValid={isValid}
              startIcon={<PlusIcon />}
              isLoading={createViewState.isLoading}
              navigateBack={() => history.goBack()}
              buttonCtaLabel={t("create.form.action.cta")}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
