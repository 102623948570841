import { FormikErrors } from "formik"
import { TFunction } from "i18next"
import { BillingsRecurringUpsert } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"
import { ValidField, validationResult } from "../../../../../Shared.Validation"

export const validateBillingsRecurring = (
  values: BillingsRecurringUpsert,
  t: TFunction,
): FormikErrors<BillingsRecurringUpsert> => {
  return validationResult({
    selectedContract: validateSelectedContract(values.selectedContract, t),
  })
}

const validateSelectedContract = (value: string[], t: TFunction) => {
  if (value.length < 1) {
    return t("shared:validation.length", {
      field: t("form.field.selectContract"),
      length: 1,
    })
  }
  return ValidField
}
