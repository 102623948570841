import {
  deleteRecurringBillingById,
  approveServiceBillingsRunById,
  sapSendServiceBillingsRunById,
  sapSendServiceBillingsById,
  recalculateServiceBillingById,
  cancelServiceBillingById,
  getRecurringBillings,
  getRecurringBillingRuns,
  createRecurringBillings,
  getRecurringBillingRunById,
  removeServiceBillingRunContractById,
} from "../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Repository"
import { DOMAIN_DEPENDENCIES } from "../../../../App.Config"
import { createEpic } from "../../../../Shared.Epic"
import {
  ServiceBillingAdminResponse,
  ServiceBillingRunMultiAdminResponse,
} from "../../../../../data/generated-sources/openapi"
import { BillingsRecurringDetail } from "../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"

export enum BillingsRecurringActionType {
  BILLINGS_RECURRING_LIST_GET = "BILLINGS_RECURRING_LIST_GET",
  BILLINGS_RECURRINGS_GET = "BILLINGS_RECURRINGS_GET",
  BILLINGS_RECURRING_GET_BY_ID = "BILLINGS_RECURRING_GET_BY_ID",
  BILLINGS_RECURRING_CREATE = "BILLINGS_RECURRING_CREATE",
  BILLINGS_RECURRING_DELETE_BY_ID = "BILLINGS_RECURRING_DELETE_BY_ID",
  BILLINGS_RECURRING_APPROVE_BY_ID = "BILLINGS_RECURRING_APPROVE_BY_ID",
  BILLINGS_RECURRING_SAP_SEND_ALL = "BILLINGS_RECURRING_SAP_SEND_ALL",
  BILLINGS_RECURRING_SAP_SEND_BY_ID = "BILLINGS_RECURRING_SAP_SEND_BY_ID",
  BILLINGS_RECURRING_RECALCULATE_BY_ID = "BILLINGS_RECURRING_RECALCULATE_BY_ID",
  BILLINGS_RECURRING_CANCEL_BY_ID = "BILLINGS_RECURRING_CANCEL_BY_ID",
  BILLINGS_RECURRING_RUN_REMOVE_BY_ID = "BILLINGS_RECURRING_RUN_REMOVE_BY_ID",
  BILLINGS_SERVICES_EXPAND_ROW = "BILLINGS_SERVICES_EXPAND_ROW",
}

export const billingsRecurringEpic = [
  createEpic<ServiceBillingRunMultiAdminResponse>(BillingsRecurringActionType.BILLINGS_RECURRING_LIST_GET, () =>
    getRecurringBillingRuns(DOMAIN_DEPENDENCIES),
  ),
  createEpic<ServiceBillingAdminResponse[]>(BillingsRecurringActionType.BILLINGS_RECURRINGS_GET, () =>
    getRecurringBillings(DOMAIN_DEPENDENCIES),
  ),
  createEpic<BillingsRecurringDetail>(BillingsRecurringActionType.BILLINGS_RECURRING_GET_BY_ID, (action) =>
    getRecurringBillingRunById(action.billingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<string>(BillingsRecurringActionType.BILLINGS_RECURRING_CREATE, (action) =>
    createRecurringBillings(action.billingsRecurringCreate, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_DELETE_BY_ID, (action) =>
    deleteRecurringBillingById(action.billingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_APPROVE_BY_ID, (action) =>
    approveServiceBillingsRunById(action.billingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_SAP_SEND_ALL, (action) =>
    sapSendServiceBillingsRunById(action.billingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_SAP_SEND_BY_ID, (action) =>
    sapSendServiceBillingsById(action.serviceBillingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_RECALCULATE_BY_ID, (action) =>
    recalculateServiceBillingById(action.serviceBillingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_CANCEL_BY_ID, (action) =>
    cancelServiceBillingById(action.serviceBillingId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(BillingsRecurringActionType.BILLINGS_RECURRING_RUN_REMOVE_BY_ID, (action) =>
    removeServiceBillingRunContractById(action.serviceBillingId, action.contractId, DOMAIN_DEPENDENCIES),
  ),
]
