import { UtilityUnitsColumns } from "../utilityUnits/UtilityUnits.Enum"
import UtilityUnitTableComponent from "../utilityUnits/UtilityUnitTable.Component"
import { BuildingsActionType } from "../../buildings/Buildings.Epic"
import { UtilityUnitApiEnum } from "../Components.Enums"
import { utilityUnitsColumnComparator } from "../utilityUnits/UtilityUnits.Comparator"
import { UtilityUnitResponse } from "../../../../../data/generated-sources/openapi"

export const UTILITY_UNIT_LIST_COMPONENT_CONFIG = {
  utilityUnit: {
    name: "utilityUnit",
    header: {
      title: "utilityUnit:label.name",
      showButton: false,
      showFilter: true,
    },
    columnEnums: UtilityUnitsColumns,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: UtilityUnitsColumns.NAME,
      direction: "desc",
    },
    columnComparator: utilityUnitsColumnComparator,
    tableHeaders: [
      {
        column: UtilityUnitsColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: UtilityUnitsColumns.NAME,
        label: "utilityUnit:label.name",
        width: "10%",
        orderable: true,
      },
      {
        column: UtilityUnitsColumns.TYPE,
        label: "meters:label.type",
        width: "20%",
        orderable: true,
      },
      {
        column: UtilityUnitsColumns.CURRENT_PARTICIPANT,
        label: "utilityUnit:label.currentParticipant",
        width: "30%",
        orderable: false,
      },
      {
        column: UtilityUnitsColumns.BUILDING,
        label: "utilityUnit:label.building",
        width: "30%",
        orderable: true,
      },
    ],
    renderTableRows: (item: UtilityUnitResponse) => (
      <UtilityUnitTableComponent itemConfig={item} key={item.id} showBuildingLayout />
    ),
    dispatchEvent: {
      type: BuildingsActionType.BUILDINGS_LIST_GET,
    },
  },
}

export const UTILITY_UNIT_API_CONFIG = {
  utilityUnit: UtilityUnitApiEnum,
}
