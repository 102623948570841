import { I18nNamespace } from "../../../App.i18n"

export const ContractsI18n: I18nNamespace = {
  name: "contracts",
  de: {
    "list.title": "VERTRÄGE",
    "list.label.status": "STATUS",
    "field.label.zev": "ZEV",
    "field.label.area": "AREAL",
    "field.label.start-date": "ANFANGSDATUM",
    "field.label.end-date": "ENDDATUM",
    "field.label.product": "PRODUKT",
    "list.label.contract-number": "VERTRAGSNUMMER",
    "list.status.approved": "ZUGELASSEN",
    "list.status.draft": "LUFTZUG",
    "list.status.terminated": "BEENDET",
    "detail.replace-contract": "Vertag ersetzen",
    "detail.approve-contract": "Vertag Freigeben",
    "detail.title": "VERTRAG DETAILS",
    "update.delete-contract": "Vertrag löschen",
    "update.title": "VERTRAG BEARBEITEN",
    "detail.replace.form.field.currentContractEndDate": "aktuelles Vertrags-Enddatum",
    "detail.replace.form.field.requiredEndDate": "Enddatum",
    "detail.replace.form.chooseNewProduct": "NEUES PRODUKT WÄHLEN",
    "detail.replace.form.product": "Produkt",
    "detail.replace.form.cta": "Vertrag ersetzen",
    "detail.release.form.createInitialInvoice": "Erstrechnung erstellen",
    "detail.release.form.createNow.cta": "Ja, jetzt erstellen",
    "detail.release.form.contractActivatedNotice":
      "Der Vertrag wurde aktiviert. Möchten Sie die erste Rechnung erstellen?",
    "detail.replace.success": "Der Vertrag wurde erfolgreich ersetzt",
    "detail.approve.success": "Der Vertrag wurde erfolgreich freigegeben",
    "detail.delete.success": "Der Vertrag wurde erfolgreich gelöscht",
    "form.field.startDate": "Anfangsdatum",
    "form.field.endDate": "Enddatum",
    "form.subtitle.product": "Produkt",
    "form.field.product": "Produkt",
    "form.action.cta": "Vertrag speichern",
    "form.alert.success": "Die Änderungen am Vertrag wurden erfolgreich gespeichert",
    "form.updateContract": "Verträge speichern",
    "dropDown.placeholder": "Verträge suchen und hinzufügen",
    "create.title": "VERTRAG ERSTELLEN",
    "create.form.action.cta": "Vertrag erstellen",
    "create.form.success": "Das vertrag wurde erfolgreich angelegt. Gehen Sie zum vertrag",
    "table.title": "VERTRÄGE",
    "table.header.contractName": "Vertrag",
    "table.header.area": "Areal",
    "table.header.contractNumber": "Vertragsnummer",
    "table.header.product": "Produkt",
    "table.header.startDate": "Anfangsdatum",
    "table.header.endDate": "Enddatum",
    "field.label.contractor": "Vertragspartner",
    "field.label.externalReferenceNumber": "Externe Referenznummer",
    "field.label.address": "Adresszusatz",
    "field.label.street": "Strasse",
    "field.label.houseNumber": "Nummer (Nr.)",
    "field.label.postalCode": "PLZ",
    "field.label.city": "Ort",
    "field.label.telephone": "Telefon",
    "field.label.mobile": "Mobile",
    "field.label.email": "Korrespondenz E-Mail",
    "contractSelector.contractEndDate": "Vertrag endet am {{date}}",
    "contractSelector.successorStarDate": "Produktwechsel am {{date}}",
  },
  en: {
    "list.title": "CONTRACTS",
    "list.label.status": "STATUS",
    "field.label.zev": "ZEV",
    "field.label.area": "AREA",
    "field.label.start-date": "START DATE",
    "field.label.end-date": "END DATE",
    "field.label.product": "PRODUCT",
    "list.label.contract-number": "CONTRACT NUMBER",
    "list.status.approved": "APPROVED",
    "list.status.draft": "DRAFT",
    "list.status.terminated": "TERMINATED",
    "detail.title": "CONTRACT DETAILS",
    "detail.replace-contract": "Replace contract",
    "detail.approve-contract": "Approve contract",
    "update.delete-contract": "Delete contract",
    "update.title": "CONTRACTS UPDATE",
    "detail.replace.form.field.currentContractEndDate": "current contract end date",
    "detail.replace.form.field.requiredEndDate": "End date",
    "detail.replace.form.chooseNewProduct": "CHOOSE A NEW PRODUCT",
    "detail.replace.form.product": "Product",
    "detail.replace.form.cta": "Replace contract",
    "detail.release.form.createInitialInvoice": "Create initial invoice",
    "detail.release.form.createNow.cta": "Yes, create now",
    "detail.release.form.contractActivatedNotice":
      "The contract has been activated. Do you want to create the initial invoice?",
    "detail.replace.success": "The contract was successfully replaced",
    "detail.approve.success": "The contract was successfully approved",
    "detail.delete.success": "The contract was successfully deleted",
    "form.field.startDate": "Start date",
    "form.field.endDate": "End date",
    "form.subtitle.product": "Product",
    "form.field.product": "Product",
    "form.action.cta": "Save contract",
    "form.alert.success": "The changes to the contract have been saved successfully",
    "form.updateContract": "Save Contracts",
    "dropDown.placeholder": "Search and add contracts",
    "create.title": "CREATE CONTRACT",
    "create.form.action.cta": "Create contract",
    "create.form.success": "The contract was created successfully. Go to contract",
    "table.title": "CONTRACTS",
    "table.header.contractNumber": "Contract Number",
    "table.header.contractName": "Contract",
    "table.header.area": "Area",
    "table.header.product": "Product",
    "table.header.startDate": "Start Date",
    "table.header.endDate": "End Date",
    "field.label.contractor": "Contractor",
    "field.label.externalReferenceNumber": "External reference number",
    "field.label.address": "Address",
    "field.label.street": "Street",
    "field.label.houseNumber": "House number",
    "field.label.postalCode": "Post code",
    "field.label.city": "City",
    "field.label.telephone": "Telephone",
    "field.label.mobile": "Mobile",
    "field.label.email": "Mail",
    "contractSelector.contractEndDate": "Contract ends on {{date}}",
    "contractSelector.successorStarDate": "Product change on {{date}}",
  },
}
