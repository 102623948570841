import { AxiosPromise } from "axios"
import { getContractManagementById } from "../contracts/Contracts.Repository"
import { getProductByIdNew } from "../products/Products.Repository"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import {
  BillingScope,
  MediumZevAdminResponse,
  MediumZevAdminUpdateRequest,
  VewaConfigCreateRequest,
  VewaConfigUpdateRequest,
} from "../../../../data/generated-sources/openapi"

export const getProductForExistingContractById = async (contractId: string) => {
  try {
    const contract = await getContractManagementById(contractId)
    const product = await getProductByIdNew(contract.productId)
    return product.data
  } catch (e) {
    throw new Error(e)
  }
}

export const getMediumZevByContractId = (contractId: string): AxiosPromise<MediumZevAdminResponse> =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractMediumByContractId(contractId, apiHeaders(DOMAIN_DEPENDENCIES))

export const getVewaCostSharesByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetVewaConfigByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const updateMediumZevByContractId = (contractId: string, zevMediums: MediumZevAdminUpdateRequest) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminPutContractMediumByContractId(
    contractId,
    zevMediums,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const getVewaBuildingsMeterByContractId = (contractId: string) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminGetPagedVewaBuildingMetersByContractId(
    contractId,
    1,
    100000,
    "",
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const updateVewaConfigByContractId = (contractId: string, vewaConfig: VewaConfigUpdateRequest) => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminUpdateVewaConfig(
    contractId,
    vewaConfig,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const removeVewaConfigByContractId = (contractId: string, billingScope: BillingScope) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminRemoveBillingScopeFromVewaConfig(
    contractId,
    billingScope,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const addVewaConfigByContractId = (contractId: string, billingScope: VewaConfigCreateRequest) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminPostVewaConfigForBillingScopeByContractId(
    contractId,
    billingScope,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
