import { useEffect, useState } from "react"
import { Stack, Typography } from "@mui/material"
import { GridRowSelectionModel } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"

import { filterArrayBySearchInput } from "../../../../Shared.Utils"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { ParticipantResponse } from "../../../../../data/generated-sources/openapi"
import { DataGridTable, DefaultCell } from "../../../../../uikit/dataGridTable"
import { GridBaseColDef } from "@mui/x-data-grid/internals"

export const AreaParticipantsList = ({
  isLoading,
  participants,
  onSelectParticipant,
}: {
  isLoading: boolean
  participants: ParticipantResponse[]
  onSelectParticipant: (participantId: string) => void
}) => {
  const { t } = useTranslation()
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [participantList, setParticipantList] = useState<ParticipantResponse[]>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const UnitParticipantsListColumns: Array<GridBaseColDef> = [
    {
      field: "lastName",
      headerName: t("customer-participants:tableHeader.lastName"),
      flex: 250,
      valueGetter: (_, row) => row.personalData?.lastName || "-",
      renderCell: DefaultCell,
    },
    {
      field: "firstName",
      headerName: t("customer-participants:tableHeader.firstName"),
      renderCell: DefaultCell,
      flex: 250,
    },
    {
      field: "email",
      headerName: t("customer-participants:tableHeader.email"),
      valueGetter: (_, row) => row?.contactDetails?.email || "-",
      renderCell: DefaultCell,
      flex: 250,
    },
    {
      field: "phone",
      headerName: t("customer-participants:tableHeader.phone"),
      valueGetter: (_, row) => row?.contactDetails?.phone || "-",
      renderCell: DefaultCell,
      flex: 250,
    },
    {
      field: "utilityUnit.",
      headerName: t("customer-participants:tableHeader.utilityUnit"),
      valueGetter: (_, row) => row?.participations[0]?.utilityUnit?.name || "-",
      renderCell: DefaultCell,
      flex: 200,
    },
  ]

  const filterData = (searchInput: string) => {
    if (participants) {
      const filtered = filterArrayBySearchInput<ParticipantResponse>(participants, searchInput)
      setParticipantList(filtered)
    }
  }

  useEffect(() => {
    setParticipantList(participants)
  }, [participants])

  useEffect(() => {
    onSelectParticipant("")
  }, [])

  return (
    <>
      <Stack spacing={2} pb={5} pt={2}>
        <Typography fontSize={24} fontWeight={400} mt={3} mb={5}>
          {t("onboarding:participantsInfo.selectParticipant")}
        </Typography>
        <SingleLineTextField
          autoFocus={false}
          id="filter"
          name="filter"
          type="text"
          sx={{ width: "24rem" }}
          label={t("shared:form.search.label")}
          value={filterQuery}
          onChange={(event) => {
            setFilterQuery(event.target.value)
            filterData(event.target.value)
          }}
        />
      </Stack>

      <DataGridTable<ParticipantResponse>
        rows={participantList}
        columns={UnitParticipantsListColumns}
        loading={isLoading}
        checkboxSelection
        onCellClick={({ id }) => {
          if (id === rowSelectionModel[0]) {
            setRowSelectionModel([])
            onSelectParticipant("")
          } else {
            setRowSelectionModel([id])
            onSelectParticipant(id?.toString())
          }
        }}
        rowSelectionModel={rowSelectionModel}
        defaultPageSize={10}
      />
    </>
  )
}
