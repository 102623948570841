import React, { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import DateUtils from "../../../../../services/utils/DateUtils"
import { useQueryDefaultOptions } from "../../../../Shared.Utils"
import { ProgressIndicator } from "../../../../../uikit/progress/ProgressIndicator"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import AnaliseWrapComponent from "../../../shared/analiyse/AnaliseWrap.Component"
import { getZevAnaliseByUtilityUnitId } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { getBarChartColors, getTabList } from "../../../shared/analiyse/charts/chart.config"
import { ChartTabsEnum } from "../../../shared/analiyse/Analise.Enum"
import { Medium, MeterReadingsCalcResolution } from "../../../../../data/generated-sources/openapi"
import { formatDateRange, getEndDate, getStartDate } from "../../../../../services/utils/AnalyseUtils"

interface UtilityUnitAnaliseInterface {
  utilityUnitId: string
}

const UtilityUnitAnalise = ({ utilityUnitId }: UtilityUnitAnaliseInterface) => {
  const { t } = useTranslation()
  const [timestamp, setTimestamp] = useState(DateUtils.getNowTimestamp())
  const [startDate, setStartDate] = useState(DateUtils.getFirstDayOfMonth(timestamp))
  const [endDate, setEndDate] = useState(DateUtils.getLastDayOfMonth(timestamp))
  const [selectedDateRange, setSelectedDateRange] = useState(MeterReadingsCalcResolution.MONTH)
  const [selectedTab, setSelectedTab] = useState<string>(ChartTabsEnum.ELECTRICITY)
  const [chartData, setChartData] = useState<unknown>()
  const [heatWaterTotals, setHeatWaterTotals] = useState<unknown>()

  const { data, isLoading, isFetchedAfterMount, isError, isSuccess, isRefetching, remove, refetch } = useQuery(
    ["getUtilityUnitAnalise"],
    () =>
      getZevAnaliseByUtilityUnitId(
        utilityUnitId,
        startDate,
        endDate,
        false,
        formatDateRange(selectedDateRange) as MeterReadingsCalcResolution,
      ),
    {
      enabled: !!utilityUnitId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    if (startDate && endDate && selectedDateRange) getAnalyseData()
  }, [startDate, endDate, selectedTab, utilityUnitId])

  useEffect(() => {
    if (startDate && endDate && selectedDateRange && timestamp) {
      setStartDate(getStartDate(timestamp, selectedDateRange) as string)
      setEndDate(getEndDate(timestamp, selectedDateRange) as string)
    }
  }, [selectedDateRange])

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  const getAnalyseData = () => {
    refetch()
  }

  useEffect(() => {
    getFormattedHeatData()
  }, [data, selectedTab, utilityUnitId])

  const getDateRange = (range: MeterReadingsCalcResolution) => {
    setSelectedDateRange(range)
  }

  const getTimeRange = (date: number) => {
    setTimestamp(date)
    setStartDate(getStartDate(date, selectedDateRange) as string)
    setEndDate(getEndDate(date, selectedDateRange) as string)
  }

  const getFormattedHeatData = () => {
    const total1 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.HEAT : Medium.WARM_WATER]
    const total2 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.COLD : Medium.COLD_WATER]
    // eslint-disable-next-line
    const combinedConsumption: any = {}

    if (total1) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total1?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        combinedConsumption[dateTime] = { produced: consumption }
      }
    }

    if (total2) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total2?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        if (combinedConsumption[dateTime]) {
          combinedConsumption[dateTime].energyHighTariff = consumption
        } else {
          combinedConsumption[dateTime] = { energyHighTariff: consumption }
        }
      }
    }

    const combinedArray = Object.keys(combinedConsumption).map((dateTime) => ({
      dateTime,
      ...combinedConsumption[dateTime],
    }))
    setChartData(combinedArray)
    setHeatWaterTotals({
      total1: total1?.totalConsumption ?? 0,
      total2: total2?.totalConsumption ?? 0,
    })
  }

  if (isLoading) return <ProgressIndicator />

  return (
    <>
      <Box>
        <ErrorAlert visible={isError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
      </Box>
      {isSuccess && isFetchedAfterMount && (
        <AnaliseWrapComponent
          handleTimeChange={getTimeRange}
          handleDateChange={getDateRange}
          headerTabs={getTabList(data)}
          handleSelectTab={setSelectedTab}
          meterReadingDateRange={selectedDateRange}
          barColors={getBarChartColors(selectedTab, t)}
          isLoading={isRefetching}
          selectedTab={selectedTab}
          analiseData={
            selectedTab === ChartTabsEnum.ELECTRICITY ? data?.zev : { totals: heatWaterTotals, data: chartData }
          }
        />
      )}
    </>
  )
}

export default UtilityUnitAnalise
