import { Grid, Paper } from "@mui/material"
import { FormikHandlers, FormikState } from "formik"
import { useTranslation } from "react-i18next"
import { BuildingUpsert } from "../../../../../domain/portal/admin/buildings/Buildings.Model"
import { FormMode } from "../../../../../uikit/form/FormView"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { validationError } from "../../../../Shared.Validation"

interface BuildingsFormProps {
  mode: FormMode
}

export const BuildingsForm = (
  props: Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikState<BuildingUpsert>, "errors" | "values" | "touched"> &
    BuildingsFormProps,
) => {
  const { t } = useTranslation("buildings")
  const { handleChange, handleBlur, touched, values, errors, mode } = props
  return (
    <Paper>
      <Grid container>
        {mode === FormMode.UPDATE && (
          <Grid item xs={12} p={2}>
            <StatusView statusType={values.statusType} />
          </Grid>
        )}
        <Grid item xs={6} p={2}>
          <SingleLineTextField
            required
            autoFocus
            name="buildingObject"
            type="text"
            label={t("form.field.buildingName")}
            helperText={validationError(errors.buildingObject, touched.buildingObject)}
            value={values.buildingObject}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} p={2}>
          <SingleLineTextField
            required
            name="addressStreet"
            type="text"
            label={t("form.field.addressStreet")}
            helperText={validationError(errors.addressStreet, touched.addressStreet)}
            value={values.addressStreet}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} p={2}>
          <SingleLineTextField
            required
            name="addressHouseNumber"
            type="text"
            label={t("form.field.addressHouseNumber")}
            helperText={validationError(errors.addressHouseNumber, touched.addressHouseNumber)}
            value={values.addressHouseNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} p={2}>
          <SingleLineTextField
            required
            name="addressPostCode"
            inputProps={{ maxLength: 6 }}
            type="text"
            label={t("form.field.addressPostCode")}
            helperText={validationError(errors.addressPostCode, touched.addressPostCode)}
            value={values.addressPostCode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} p={2}>
          <SingleLineTextField
            required
            name="addressCity"
            type="text"
            label={t("form.field.addressCity")}
            helperText={validationError(errors.addressCity, touched.addressCity)}
            value={values.addressCity}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
