import React, { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useQueryDefaultOptions } from "../../../../../Shared.Utils"
import AnaliseWrapComponent from "../../../../shared/analiyse/AnaliseWrap.Component"
import { Medium, MeterReadingsCalcResolution } from "../../../../../../data/generated-sources/openapi"
import { getBarChartColors, getTabList } from "../../../../shared/analiyse/charts/chart.config"
import DateUtils from "../../../../../../services/utils/DateUtils"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { useTranslation } from "react-i18next"
import { ProgressIndicator } from "../../../../../../uikit/progress/ProgressIndicator"
import { Box } from "@mui/material"
import { ChartTabsEnum } from "../../../../shared/analiyse/Analise.Enum"
import { getMeterReadingForUtilityUnit } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { formatDateRange, getEndDate, getStartDate } from "../../../../../../services/utils/AnalyseUtils"

interface EnergyConsumptionPropsInterface {
  utilityUnitId: string
}

const EnergyConsumption = ({ utilityUnitId }: EnergyConsumptionPropsInterface) => {
  const { t } = useTranslation()
  const [timestamp, setTimestamp] = useState(DateUtils.getNowTimestamp())
  const [startDate, setStartDate] = React.useState(DateUtils.getFirstDayOfMonth(timestamp))
  const [endDate, setEndDate] = React.useState(DateUtils.getLastDayOfMonth(timestamp))
  const [selectedDateRange, setSelectedDateRange] = useState(MeterReadingsCalcResolution.MONTH)
  const [selectedTab, setSelectedTab] = useState<string>(ChartTabsEnum.ELECTRICITY)
  const [chartData, setChartData] = useState<unknown>()
  const [heatWaterTotals, setHeatWaterTotals] = useState<unknown>()

  const { data, isLoading, isFetchedAfterMount, isError, isSuccess, isRefetching, refetch } = useQuery(
    ["customerGetEnergyConsumption"],
    () =>
      getMeterReadingForUtilityUnit(
        utilityUnitId,
        startDate,
        endDate,
        false,
        formatDateRange(selectedDateRange) as MeterReadingsCalcResolution,
      ),
    {
      enabled: !!utilityUnitId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    if (startDate && endDate && selectedDateRange) getAnalyseData()
  }, [startDate, endDate, selectedDateRange, selectedTab])

  useEffect(() => {
    if (startDate && endDate && selectedDateRange && timestamp) {
      setStartDate(getStartDate(timestamp, selectedDateRange) as string)
      setEndDate(getEndDate(timestamp, selectedDateRange) as string)
    }
  }, [startDate, endDate, selectedDateRange, timestamp])

  useEffect(() => {
    getFormattedHeatData()
  }, [data, selectedTab, utilityUnitId])

  const getFormattedHeatData = () => {
    const total1 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.HEAT : Medium.WARM_WATER]
    const total2 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.COLD : Medium.COLD_WATER]
    // eslint-disable-next-line
    const combinedConsumption: any = {}

    if (total1) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total1?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        combinedConsumption[dateTime] = { produced: consumption }
      }
    }

    if (total2) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total2?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        if (combinedConsumption[dateTime]) {
          combinedConsumption[dateTime].energyHighTariff = consumption
        } else {
          combinedConsumption[dateTime] = { energyHighTariff: consumption }
        }
      }
    }

    const combinedArray = Object.keys(combinedConsumption).map((dateTime) => ({
      dateTime,
      ...combinedConsumption[dateTime],
    }))
    setChartData(combinedArray)
    setHeatWaterTotals({
      total1: total1?.totalConsumption ?? 0,
      total2: total2?.totalConsumption ?? 0,
    })
  }

  const getAnalyseData = () => {
    refetch()
  }

  const getDateRange = (range: MeterReadingsCalcResolution) => {
    setSelectedDateRange(range)
  }
  const getTimeRange = (date: number) => {
    setTimestamp(date)
    setStartDate(getStartDate(date, selectedDateRange) as string)
    setEndDate(getEndDate(date, selectedDateRange) as string)
  }

  const mapZevData = () => {
    return data?.zev
  }

  if (isLoading) return <ProgressIndicator />

  return (
    <>
      <Box>
        <ErrorAlert visible={isError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
      </Box>
      {isSuccess && isFetchedAfterMount && (
        <AnaliseWrapComponent
          handleTimeChange={getTimeRange}
          handleDateChange={getDateRange}
          headerTabs={getTabList(data)}
          handleSelectTab={setSelectedTab}
          meterReadingDateRange={selectedDateRange}
          barColors={getBarChartColors(selectedTab, t)}
          isLoading={isRefetching}
          selectedTab={selectedTab}
          analiseData={
            selectedTab === ChartTabsEnum.ELECTRICITY ? mapZevData() : { totals: heatWaterTotals, data: chartData }
          }
        />
      )}
    </>
  )
}

export default EnergyConsumption
