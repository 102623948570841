import React, { useEffect, useState } from "react"
import {
  MediumZevAdminUpdateRequest,
  MeterResponse,
  Municipality,
  ZevMeter,
  ZevMeterType,
} from "../../../../../../../data/generated-sources/openapi"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Grid, Paper, Typography } from "@mui/material"
import { FullScreenLoader } from "../../../../../../../uikit/indicator/ProgressIndicator"
import { Form, Formik, FormikErrors, FormikValues } from "formik"
import { ErrorAlert } from "../../../../../../../uikit/Shared.Alert"
import { SelectPicker } from "../../../../../../../uikit/input/SelectPicker"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"
import {
  getMediumZevByContractId,
  updateMediumZevByContractId,
} from "../../../../../../../domain/portal/admin/mediu/Medium.Repository"
import { useQueryDefaultOptions } from "../../../../../../Shared.Utils"
import { getMeters } from "../../../../../../../domain/portal/admin/meters/Meters.Repository"
import MediumZevCardDetailComponent from "./MediumZevCardDetail.Component"
import { FormUpdateActionsView } from "../../../../../../../uikit/form/FormUpdateActions"
import { useHistory } from "react-router-dom"
import { PlusIcon } from "../../../../../../../uikit/Shared.Icon"
import { FabButton } from "../../../../../../../uikit/button/FabButton"
import DateUtils from "../../../../../../../services/utils/DateUtils"
import { getContractManagementById } from "../../../../../../../domain/portal/admin/contracts/Contracts.Repository"

const mediumObject: ZevMeter = {
  meteringCode: "",
  dateFrom: "",
  dateTo: "",
  meterType: ZevMeterType.OUTGOING,
}

const mediumZevValidation = yup.object().shape({
  meters: yup.array().of(
    yup.object().shape({
      meteringCode: yup.string().required(),
      dateFrom: yup.string().required(),
    }),
  ),
  municipality: yup.string().required(),
})

const MediumZevUpdateComponent = ({ contractId }: { contractId: string }) => {
  const [mediumList, setMediumList] = useState<ZevMeter[]>([])
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const history = useHistory()
  const { t } = useTranslation("medium")

  const { data: contractData, remove: removeContract } = useQuery(
    ["getContractForStartDate"],
    () => getContractManagementById(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  const {
    data: mediumZevData,
    isError: mediumZevIsError,
    isLoading: mediumZevIsLoading,
    remove: mediumZevRemove,
  } = useQuery<AxiosResponse, AxiosError>(
    ["getMediumZevForUpdateByContractId"],
    () => getMediumZevByContractId(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  const {
    data: metersData,
    isError: metersIsError,
    isLoading: meterIsLoading,
    remove: metersRemove,
  } = useQuery<AxiosResponse, AxiosError>(["getMetersForMedium"], () => getMeters(1, 10000, ""), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
  })

  const {
    mutate: updateMediumZevMutate,
    isError: updateMediumZevIsError,
    isLoading: updateMediumZevIsLoading,
    reset: updateMediumZevReset,
  } = useMutation(
    ["updateMediumZev"],
    (dto: MediumZevAdminUpdateRequest) => updateMediumZevByContractId(contractId, dto),
    {
      onSuccess: () => history.push(`/management-contracts/${contractId}`),
    },
  )

  useEffect(() => {
    return () => {
      updateMediumZevReset()
      metersRemove()
      mediumZevRemove()
      removeContract()
    }
  }, [updateMediumZevReset, metersRemove, mediumZevRemove, removeContract])

  useEffect(() => {
    if (metersData?.data.elements.length) {
      setMediumList([
        ...collectMeters(mediumZevData?.data.incomingMeters, ZevMeterType.INCOMING),
        ...collectMeters(mediumZevData?.data.outgoingMeters, ZevMeterType.OUTGOING),
      ])
    }
  }, [mediumZevData, metersData])

  const collectMeters = (meterIds: string[] | undefined, meterType: string) => {
    if (!meterIds || !meterIds.length) {
      return []
    }
    return metersData?.data.elements
      .filter((item: MeterResponse) => meterIds?.includes(item.id))
      .map((item: MeterResponse) => {
        return {
          meteringCode: item.meteringCode,
          dateFrom: item.billableFrom,
          dateTo: item.billableTo,
          meterType,
        }
      })
  }

  const renderMediumList = (
    values: FormikValues,
    setFieldValue: (fieldName: string, newValues: MediumZevAdminUpdateRequest) => void,
    handleChange: () => void,
    handleBlur: () => void,
    errors: FormikErrors<ZevMeter>,
  ) =>
    values?.meters?.map((medium: ZevMeter, index: number) => {
      return (
        <MediumZevCardDetailComponent
          key={index}
          formikProps={{ values: medium, setFieldValue, handleChange, handleBlur, errors }}
          index={index}
          setIsFormDirty={setIsFormDirty}
        />
      )
    })

  const addMedium = (
    values: FormikValues,
    setFieldValue: (fieldName: string, newValues: MediumZevAdminUpdateRequest) => void,
  ): void => {
    contractData?.startDate && (mediumObject["dateFrom"] = contractData?.startDate)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFieldValue("meters", [...values.meters, mediumObject])
  }

  return (
    <>
      {(mediumZevIsLoading || meterIsLoading) && <FullScreenLoader />}
      <Formik<MediumZevAdminUpdateRequest>
        initialValues={{
          meters: mediumList,
          municipality: mediumZevData?.data.municipality ?? "",
        }}
        validationSchema={mediumZevValidation}
        enableReinitialize={true}
        onSubmit={(values) => {
          const updateMediumZevDto: MediumZevAdminUpdateRequest = {
            municipality: values.municipality,
            meters: values.meters.map((item: ZevMeter) => {
              return {
                meteringCode: item.meteringCode,
                meterType: item.meterType,
                dateFrom: item?.dateFrom ? DateUtils.getDeFormatDate(item.dateFrom) : "",
                dateTo: item?.dateTo ? DateUtils.getDeFormatDate(item.dateTo) : undefined,
              }
            }),
          }
          updateMediumZevMutate(updateMediumZevDto)
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
          isValid,
          dirty,
        }: {
          values: FormikValues
          errors: FormikErrors<ZevMeter>
          handleChange: () => void
          handleBlur: () => void
          setFieldValue: (fieldName: string, newValues: MediumZevAdminUpdateRequest) => void
          isValid: boolean
          dirty: boolean
        }) => {
          return (
            <Form>
              <Paper>
                <Typography variant="h4" fontFamily="Montserrat" fontWeight="lighter" mb={3}>
                  {t("detail.title")}
                </Typography>
                <ErrorAlert
                  message={t("error-codes:OTHER")}
                  visible={metersIsError || mediumZevIsError || updateMediumZevIsError}
                />
                <Grid container>
                  <Grid item xs={6} mb={3} mt={3}>
                    <SelectPicker
                      name="type"
                      type="text"
                      label={t("label.municipality")}
                      value={values?.municipality ?? mediumZevData?.data.municipality}
                      items={Object.values(Municipality)
                        .sort((a, b) => a?.localeCompare(b))
                        .map((item: string) => {
                          return {
                            label: item,
                            value: item,
                          }
                        })}
                      onChange={(e) => {
                        setFieldValue("municipality", e.target.value)
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container mt={3}>
                  {renderMediumList(values, setFieldValue, handleChange, handleBlur, errors)}
                  <FabButton
                    size="medium"
                    icon={<PlusIcon fontSize="large" />}
                    onClick={() => addMedium(values, setFieldValue)}
                  />
                </Grid>
              </Paper>
              <FormUpdateActionsView
                isValid={isValid}
                dirty={dirty || isFormDirty}
                isLoading={updateMediumZevIsLoading}
                navigateBack={() => history.push(`/management-contracts/${contractId}`)}
                buttonCtaLabel={t("medium:label.saveBtn")}
              />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default MediumZevUpdateComponent
