import TableContainer from "@mui/material/TableContainer"
import TableCell from "@mui/material/TableCell"
import { useEffect, useMemo, useState } from "react"
import { Menu, MenuItem, Typography, Grid, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { SuccessAlert, TableRowErrorAlert } from "../../../../../uikit/Shared.Alert"
import { DEFAULT_ROWS_PER_PAGE } from "../../../../../uikit/Shared.Consts"
import { ORDERED_NUMBER_COMPARATOR, ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableFixed } from "../../../../../uikit/table/Table.Fixed"
import { TableColumnSort, TableHeader, TableHeaderView, TableAlign } from "../../../../../uikit/table/Table.HeaderView"
import { PageRowSlice, TablePaginationView } from "../../../../../uikit/table/Table.PaginationView"
import { TableRowView, TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { firstViewState } from "../../../../Shared.Reducer"
import { BillingsRecurringListState } from "./BillingsRecurringList.Reducer"
import { OpenIcon } from "../../../../../uikit/Shared.Icon"
import { mapDispatchToProps } from "./BillingsRecurringList.Connect"
import { ServiceBillingRunAdminResponse } from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"
import { StatusType } from "../../../../../domain/Domain.Model"
import { SharedListHeader } from "../../sharedListComponet/SharedListHeader.Component"

enum BillingsRecurringColumns {
  STATUS_TYPE = "STATUS_TYPE",
  PERIOD = "PERIOD",
  NUMBER_OF_BILS = "NUMBER_OF_BILS",
}

interface BillingsRecurringListComponentProps
  extends BillingsRecurringListState,
    ReturnType<typeof mapDispatchToProps> {}

export const BillingsRecurringListComponent = (props: BillingsRecurringListComponentProps) => {
  const { t } = useTranslation("billings-recurring")

  const {
    viewState,
    getRecurringBillingRuns,
    navigateToRecurringBilling,
    navigateToRecurringBillingCreate,
    navigateToZev,
    showDeleteSuccess,
  } = props

  useEffect(() => {
    if (firstViewState(viewState)) {
      getRecurringBillingRuns()
    }
  }, [viewState, getRecurringBillingRuns])

  // todo: check initial billing bussines logic.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const billingRecurringList = useMemo(() => viewState?.domainResult?.elements || [], [viewState])

  const [orderBy, setOrderBy] = useState<TableColumnSort<BillingsRecurringColumns>>({
    column: BillingsRecurringColumns.PERIOD,
    direction: "desc",
  })

  const [pageRowSlice, setPageRowSlice] = useState<PageRowSlice>({
    start: 0,
    end: DEFAULT_ROWS_PER_PAGE,
  })

  const [filterQuery, setFilterQuery] = useState<string>("")

  const tableHeaders: TableHeader<BillingsRecurringColumns>[] = [
    {
      column: BillingsRecurringColumns.STATUS_TYPE,
      label: t("list.label.status"),
      width: "15%",
    },
    {
      column: BillingsRecurringColumns.PERIOD,
      label: t("shared:label.period"),
      width: "40%",
    },
    {
      column: BillingsRecurringColumns.NUMBER_OF_BILS,
      label: t("list.label.number-of-bills"),
      width: "20%",
      align: TableAlign.right,
    },
  ]

  const columnComparator = () => {
    switch (orderBy.column) {
      case BillingsRecurringColumns.STATUS_TYPE:
        return (a: ServiceBillingRunAdminResponse, b: ServiceBillingRunAdminResponse) =>
          ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
      case BillingsRecurringColumns.PERIOD:
        return (a: ServiceBillingRunAdminResponse, b: ServiceBillingRunAdminResponse) =>
          ORDERED_STRING_COMPARATOR(a.startDate, b.startDate, orderBy.direction)
      case BillingsRecurringColumns.NUMBER_OF_BILS:
        return (a: ServiceBillingRunAdminResponse, b: ServiceBillingRunAdminResponse) =>
          ORDERED_NUMBER_COMPARATOR(a.serviceBillingIds.length, b.serviceBillingIds.length, orderBy.direction)
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const closeZevListMenu = () => {
    setAnchorEl(null)
  }
  const [zevListMenuItems] = useState<
    {
      id: string
      name: string
    }[]
  >([])
  return (
    <>
      {showDeleteSuccess && <SuccessAlert message={t("list.action.delete.success")} />}
      <Paper>
        <SharedListHeader
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          header={{
            title: t("list.title"),
            showButton: true,
            showFilter: true,
          }}
          onClick={navigateToRecurringBillingCreate}
        />
        <TableContainer>
          <TableFixed>
            <TableHeaderView<BillingsRecurringColumns>
              isLoading={viewState.isLoading}
              headers={tableHeaders}
              orderBy={orderBy}
              orderByChanged={(orderBy) => setOrderBy(orderBy)}
            />
            {viewState.domainError && (
              <TableRowErrorAlert
                colSpan={3}
                retry={() => getRecurringBillingRuns()}
                message={viewState.domainError.message}
              />
            )}
            <TableRowView<ServiceBillingRunAdminResponse>
              colSpan={3}
              rows={billingRecurringList}
              pageRowSlice={pageRowSlice}
              comparator={columnComparator}
              filterQuery={filterQuery}
              render={(billingRecurringItem) => (
                <TableRowClickable<ServiceBillingRunAdminResponse>
                  key={billingRecurringItem.id}
                  rowData={billingRecurringItem}
                  rowClick={(billingRecurringItem) => navigateToRecurringBilling(billingRecurringItem.id)}
                >
                  <TableCell align="left">
                    <StatusView statusType={StatusType[billingRecurringItem.activeState]} />
                  </TableCell>
                  <TableCell align="left">
                    {DateUtils.getDeFormatDateWithMonthString(billingRecurringItem.startDate)} -{" "}
                    {DateUtils.getDeFormatDateWithMonthString(billingRecurringItem.endDate)}
                  </TableCell>
                  <TableCell align="right">{billingRecurringItem.serviceBillingIds.length}</TableCell>
                </TableRowClickable>
              )}
            />
          </TableFixed>
          <TablePaginationView rowCount={billingRecurringList.length} onPageRowSliceChanged={setPageRowSlice} />
        </TableContainer>
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeZevListMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {zevListMenuItems.map((zev) => (
          <MenuItem
            key={zev.id}
            onClick={(event) => {
              event.stopPropagation()
              closeZevListMenu()
              navigateToZev(zev.id)
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              sx={{
                width: 240,
              }}
            >
              <Typography variant="body1">{zev.name}</Typography>
              <OpenIcon color="primary" />
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
