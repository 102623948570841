import { t } from "i18next"
import { BuildingAdminResponse, PagedBuildingAdminResponse } from "../../../../data/generated-sources/openapi"
import { StatusType } from "../../../Domain.Model"
import { BuildingListItem, BuildingsList } from "./Buildings.Model"
import { AreaAdminResponse } from "../../../../data/generated-sources/openapi/api"

export const mapBuildingListItem = (building: BuildingAdminResponse, areas: AreaAdminResponse[]): BuildingListItem => {
  const getAreaName = () => areas.filter((area) => area.id === building.areaId).map((ar) => ar.name)[0] ?? "-"
  return {
    id: building.id,
    statusType: StatusType[building.activeState],
    sortableStatusType: t(`shared:status.${StatusType[building.activeState]}`),
    buildingObject: building.name ?? "",
    address:
      `${building.address.street} ${building.address.houseNumber} ` +
      `${building.address.postalCode} ${building.address.city}`,
    areaId: building.areaId ?? "",
    areaName: getAreaName(),
  }
}

export const buildingsListMapper = (
  buildings: PagedBuildingAdminResponse,
  areas: AreaAdminResponse[],
): BuildingsList => {
  return {
    buildings: buildings.elements.map((building) => mapBuildingListItem(building, areas)),
  }
}
