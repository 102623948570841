import { ServiceBillingPosition, ServiceBillingRunAdminResponse } from "../../../../../data/generated-sources/openapi"
import { apiFormattedDateToTimestamp, appFormattedDate, formatMoneyLabel } from "../../../../Domain.Formatters"
import {
  BillingPosition,
  BillingsRecurringDetail,
  ContractServiceBillingInterface,
  ServiceBilling,
} from "./BillingsRecurring.Model"
import { DomainDependencies } from "../../../../Domain.Dependencies"
import { StatusType } from "../../../../Domain.Model"

export const billingsRecurringMapper = (
  billing: ServiceBillingRunAdminResponse,
  zevServiceBillings: ContractServiceBillingInterface[],
  deps: DomainDependencies,
): BillingsRecurringDetail => {
  return {
    id: billing.id,
    statusType: StatusType[billing.activeState],
    period: `${appFormattedDate(billing.startDate, deps)} - ${appFormattedDate(billing.endDate, deps)}`,
    contractConfigIds: billing.serviceBillingIds,
    serviceBillings: zevServiceBillings.map((zevServiceBilling) => serviceBillingMapper(zevServiceBilling, deps)),
  }
}

export const serviceBillingMapper = (
  zevServiceBilling: ContractServiceBillingInterface,
  deps: DomainDependencies,
): ServiceBilling => {
  const { contractResponse, serviceBillingAdminResponse } = zevServiceBilling
  return {
    id: serviceBillingAdminResponse.id,
    statusType: StatusType[serviceBillingAdminResponse.activeState],
    sortableStatusType: serviceBillingAdminResponse.activeState.toString(),
    period: `${appFormattedDate(serviceBillingAdminResponse.startDate, deps)} - ${appFormattedDate(
      serviceBillingAdminResponse.endDate,
      deps,
    )}`,
    sortablePeriod: apiFormattedDateToTimestamp(serviceBillingAdminResponse.startDate),
    positions: serviceBillingAdminResponse.positions.map((position, index) => billingPositionMapper(position, index)),
    billingType: serviceBillingAdminResponse.billingType,
    contractId: contractResponse.id,
    contractName: contractResponse.name,
    totalAmountDue: formatMoneyLabel(serviceBillingAdminResponse.totalAmountDue),
    sortableTotalAmountDue: parseFloat(serviceBillingAdminResponse.totalAmountDue),
    accountingStatus: serviceBillingAdminResponse.accountingStatus,
    sortableAccountingStatus: serviceBillingAdminResponse.accountingStatus.toString(),
    invoiceReferenceNumber: serviceBillingAdminResponse.invoiceReferenceNumber ?? "",
    orderReferenceNumber: serviceBillingAdminResponse.orderReferenceNumber ?? "",
    submissionError: serviceBillingAdminResponse.accountingErrorMessage,
  }
}

export const billingPositionMapper = (position: ServiceBillingPosition, index: number): BillingPosition => {
  return {
    id: `${index}`,
    name: position.name,
    quantity: `${position.quantity}`,
    sortableQuantity: position.quantity,
    price: formatMoneyLabel(position.price),
    sortablePrice: parseFloat(position.price),
    finalAmountDue: formatMoneyLabel(position.finalAmountDue),
    sortableFinalAmountDue: parseFloat(position.finalAmountDue),
  }
}
