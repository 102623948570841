import { Redirect, Route, Switch } from "react-router-dom"
import BillingsInitialDetailConnect from "./billings/initial/BillingsInitialDetail.Connect"
import BillingsInitialListConnect from "./billings/initial/BillingsInitialList.Connect"
import BillingsRecurringCreateConnect from "./billings/recurring/BillingsRecurringCreate.Connect"
import BillingsRecurringDetailConnect from "./billings/recurring/BillingsRecurringDetail.Connect"
import BillingsRecurringListConnect from "./billings/recurring/BillingsRecurringList.Connect"
import BuildingsCreateConnect from "./buildings/BuildingsCreate.Connect"
import BuildingsDetailConnect from "./buildings/BuildingsDetail.Connect"
import BuildingsListConnect from "./buildings/BuildingsList.Connect"
import { ContractBillingForm } from "./contractsManagement/billing/ContractBillingForm"
import { ContractContactForm } from "./contractsManagement/contact/ContractContactForm"
import { ContractMeterActivate } from "./contractsManagement/meters/ContractMeterActivate"
import EditBuildingsComponent from "./contractsManagement/building/EditBuildings.Component"
import ContractMasterDataCreateForm from "./contractsManagement/masterData/ContractMasterDataCreateForm"
import ContractMasterDataUpdateForm from "./contractsManagement/masterData/ContractMasterDataUpdateForm"
import { ContractManagerListForm } from "./contractsManagement/managers/ContractManagerForm"
import { ContractElectricityPricesForm } from "./contractsManagement/electricityPrices/ContractElectricityPricesForm"
import MeterDetailComponent from "./meters/MeterDetail.Component"
import MeterUpdateForm from "./meters/components/MeterUpdateForm"
import MeterCreateForm from "./meters/components/MeterCreateForm"
import BuildingMeterCreateForm from "./meters/components/BuildingMeterCreateForm"
import MyProfileConnect from "./my-profile/MyProfileDetail.Connect"
import MyProfileUpdateConnect from "./my-profile/MyProfileUpdate.Connect"
import ProductsCreateConnect from "./products/ProductsCreate.Connect"
import { ParticipantDetails } from "./participant/ParticipantDetails"
import ParticipantDetailsEdit from "./participant/ParticipantDetailsEdit"
import ProductsDetailConnect from "./products/ProductsDetail.Connect"
import ProductListConnect from "./products/ProductsList.Connect"
import ProductsUpdateConnect from "./products/ProductsUpdate.Connect"
import ProfilesCreateConnect from "./profiles/ProfilesCreate.Connect"
import ProfilesDetailConnect from "./profiles/ProfilesDetail.Connect"
import ProfilesListConnect from "./profiles/ProfilesList.Connect"
import ProfilesUpdateConnect from "./profiles/ProfilesUpdate.Connect"
import ProfileContractsModify from "./profiles/ProfileContractsModify"
import SettingsChangePasswordConnect from "./settings/SettingsChangePassword.Connect"
import SettingsDetailConnect from "./settings/SettingsDetail.Connect"
import { COMPONENTS_CONFIG } from "./sharedComponentsConfig/Components.Config"
import SharedListComponent from "./sharedListComponet/SharedList.Component"
import { TaskList } from "./taskList/TaskList"
import UtilityUnitDetailUpdateComponent from "./utilityUnit/UtilityUnitDetailUpdate.Component"
import UtilityUnitCreateForm from "./utilityUnit/views/UtilityUnitCreateForm"
import AreaCreateUpdateComponent from "./area/AreaCreateUpdate.Component"
import MasterDataComponent from "./masterData/MasterData.Component"
import { MASTER_DATA_CONFIG } from "./masterData/MasterData.Config"
import BuildingsUpdateConnect from "./buildings/BuildingsUpdate.Connect"
import MediumUpdateWrapperComponent from "./contractsManagement/medium/update/MediumUpdateWrapper.Component"
import AreaCreateParticipant from "./area/components/AreaCreateParticipant"
import { VewaBillingValidateDetailsPage } from "./billings/VewaBillingValidateDetailsPage"
import { VewaParticipantsDoneBillsPage } from "./billings/VewaParticipantsDoneBillsPage"
import { EditServiceBilling } from "./contractsManagement/serviceBilling/EditServiceBilling"
import { ZevAllParticipantBilling } from "./billings/participant/ZevAllParticipantBilling"
import { ZevIndividualParticipantBilling } from "./billings/participant/ZevIndividualParticipantBilling"
import { ZevAllDoneParticipantBilling } from "./billings/participant/ZevAllDoneParticipantBilling"
import { ZevIndividualDoneParticipantBilling } from "./billings/participant/ZevIndividualDoneParticipantBilling"
import { TariffSuppliers, TariffSupplierAddUpdate } from "./tariffManagement"
import { Contracts } from "./contracts/Contracts"
import { ContractCommentUpdate } from "./contractsManagement/comments/ContractCommentUpdate"

export interface AdminPortalRouteParams {
  buildingId: string
  serviceComponentId: string
  billingId: string
  contractId: string
  profileId: string
  consumptionPointId: string
  participantId: string
  productId: string
  priceId: string
  meterId: string
  utilityUnitId: string
  areaId: string
  id: string
  productType: string
  tariffId: string
}

export const AdminPortalRoutes = () => (
  <div className="ckw-admin-portal-wrapper">
    <Switch>
      <Route path="/participants/:participantId/update" component={ParticipantDetailsEdit} />
      <Route path="/participants/:participantId" component={ParticipantDetails} />
      <Route path="/products/create" component={ProductsCreateConnect} />
      <Route path="/products/:productId/update" component={ProductsUpdateConnect} />
      <Route path="/products/:productId" component={ProductsDetailConnect} />
      <Route path="/products" component={ProductListConnect} />
      <Route path="/profiles/create" component={ProfilesCreateConnect} />
      <Route path="/profiles/:profileId/contracts/update" component={ProfileContractsModify} />
      <Route path="/profiles/:profileId/update" component={ProfilesUpdateConnect} />
      <Route path="/profiles/:profileId" component={ProfilesDetailConnect} />
      <Route path="/profiles" component={ProfilesListConnect} />
      <Route path="/my-profile/:profileId/update" component={MyProfileUpdateConnect} />
      <Route path="/my-profile" component={MyProfileConnect} />
      <Route path="/buildings/:buildingId/utility-unit/create" component={UtilityUnitCreateForm} />
      <Route path="/buildings/:buildingId/meters/create" component={BuildingMeterCreateForm} />
      <Route path="/buildings/:buildingId/update" component={BuildingsUpdateConnect} />
      <Route path="/buildings/:buildingId" component={BuildingsDetailConnect} />
      <Route path="/buildings" component={BuildingsListConnect} />
      <Route path="/billings/initial/details/:billingId" component={BillingsInitialDetailConnect} />
      <Route path="/billings/initial" component={BillingsInitialListConnect} />
      <Route path="/billings/recurring/create" component={BillingsRecurringCreateConnect} />
      <Route path="/billings/recurring/details/:billingId" component={BillingsRecurringDetailConnect} />
      <Route path="/billings/recurring" component={BillingsRecurringListConnect} />
      <Route path="/tasklist/participants/:participantId/update" component={ParticipantDetailsEdit} />
      <Route path="/tasklist/participants/:participantId" component={ParticipantDetails} />
      <Route path="/tasklist" component={TaskList} />
      <Route path="/settings/reset-password" component={SettingsChangePasswordConnect} />
      <Route path="/settings" component={SettingsDetailConnect} />
      <Route path="/meters/:meterId/update" component={MeterUpdateForm} />
      <Route path="/meters/:meterId" component={MeterDetailComponent} />
      <Route path="/meters">
        <SharedListComponent configNew={COMPONENTS_CONFIG.meters} />
      </Route>
      <Route
        path="/utility-units/:utilityUnitId/participants/:participantId/update"
        component={ParticipantDetailsEdit}
      />
      <Route path="/utility-units/:utilityUnitId/participants/:participantId" component={ParticipantDetails} />
      <Route path="/utility-units/:utilityUnitId/meter/create" component={MeterCreateForm} />
      <Route path="/utility-units/:utilityUnitId/update" component={UtilityUnitDetailUpdateComponent} />
      <Route path="/utility-units/:id">
        <MasterDataComponent config={MASTER_DATA_CONFIG.utilityUnitDetails} />
      </Route>
      <Route path="/utility-units">
        <SharedListComponent configNew={COMPONENTS_CONFIG.utilityUnit} />
      </Route>
      <Route path="/area/create/new" component={AreaCreateUpdateComponent} />
      <Route path="/area/:areaId/participants/create" component={AreaCreateParticipant} />
      <Route path="/area/:areaId/contract/create" component={ContractMasterDataCreateForm} />
      <Route path="/area/:areaId/contract/:contractId/update" component={ContractMasterDataUpdateForm} />
      <Route path="/area/:id/building/create" component={BuildingsCreateConnect} />
      <Route path="/area/:id/update" component={AreaCreateUpdateComponent} />
      <Route path="/area/:id">
        <MasterDataComponent config={MASTER_DATA_CONFIG.area} />
      </Route>
      <Route path="/area">
        <SharedListComponent configNew={COMPONENTS_CONFIG.area} />
      </Route>

      <Route path="/management-contracts/:id/participants/:participantId/update" component={ParticipantDetailsEdit} />
      <Route path="/management-contracts/:id/participants/:participantId" component={ParticipantDetails} />
      <Route path="/management-contracts/:id/profiles/:profileId/update" component={ProfilesUpdateConnect} />
      <Route path="/management-contracts/:id/profiles/:profileId" component={ProfilesDetailConnect} />
      <Route path="/management-contracts/:id/billings/all/:billingId/edit" component={ZevAllParticipantBilling} />
      <Route
        path="/management-contracts/:id/billings/individual/:billingId/edit"
        component={ZevIndividualParticipantBilling}
      />
      <Route
        path="/management-contracts/:id/billings/all/:billingId/finalize"
        component={ZevAllDoneParticipantBilling}
      />
      <Route
        path="/management-contracts/:id/billings/individual/:billingId/finalize"
        component={ZevIndividualDoneParticipantBilling}
      />
      <Route
        path="/management-contracts/:id/billings/vewa/:billingId/validate"
        component={VewaBillingValidateDetailsPage}
      />
      <Route
        path="/management-contracts/:id/billings/vewa/:billingId/bills"
        component={VewaParticipantsDoneBillsPage}
      />
      <Route path="/management-contracts/:id/area/:areaId/participants/create" component={AreaCreateParticipant} />
      <Route path="/management-contracts/:id/service-billing/update" component={EditServiceBilling} />
      <Route path="/management-contracts/:id/building/update" component={EditBuildingsComponent} />
      <Route path="/management-contracts/:id/billing/update" component={ContractBillingForm} />
      <Route path="/management-contracts/:id/contact/update" component={ContractContactForm} />
      <Route path="/management-contracts/:id/managers/update" component={ContractManagerListForm} />
      <Route path="/management-contracts/:id/master-data/update" component={ContractMasterDataUpdateForm} />
      <Route path="/management-contracts/:id/meters/activate" component={ContractMeterActivate} />
      <Route path="/management-contracts/:id/medium/:productType/update" component={MediumUpdateWrapperComponent} />
      <Route path="/management-contracts/:id/prices/update" component={ContractElectricityPricesForm} />
      <Route path="/management-contracts/:id/comment/update" component={ContractCommentUpdate} />
      <Route path="/management-contracts/:id">
        <MasterDataComponent config={MASTER_DATA_CONFIG.contractManagement} />
      </Route>
      <Route path="/management-contracts" component={Contracts} />
      <Route path="/tariff/:tariffId/edit" component={TariffSupplierAddUpdate} />
      <Route path="/tariff/add" component={TariffSupplierAddUpdate} />
      <Route path="/tariff" component={TariffSuppliers} />
      <Route path="/">
        <Redirect to="/area/" />
      </Route>
    </Switch>
  </div>
)
