import { Box, styled, Typography, Stack } from "@mui/material"
import { GridToolbarQuickFilter } from "@mui/x-data-grid"
import { t } from "i18next"

import { FabButton, PrimaryButton } from "../../../../../uikit/button"
import { DownloadIcon, PlusIcon } from "../../../../../uikit/Shared.Icon"

export const DataGridHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(4),
}))

export const DataGridQuickFilterSearchInput = styled(GridToolbarQuickFilter)(({ theme }) => ({
  fontWeight: 300,
  minWidth: "270px",
  fontSize: theme.typography.pxToRem(14),
  marginRight: theme.spacing(2),
  paddingBottom: 0,

  "& .MuiInputBase-root": {
    borderBottom: "1px solid #1a1a1a",
    ":hover:before": {
      borderBottom: "1.5px solid #1a1a1a",
    },
  },
}))

export interface IDataGridHeader {
  title: string
  showFilter?: boolean
  showButton?: boolean
  buttonDisabled?: boolean
  showDownloadBtn?: boolean
  buttonIcon?: React.ReactNode
  onDownloadClick?: () => void
  onActionButtonClick?: () => void
}

export const DataGridTableHeader = ({
  title,
  showButton = true,
  showFilter = true,
  buttonIcon,
  showDownloadBtn,
  buttonDisabled,
  onActionButtonClick,
  onDownloadClick,
}: IDataGridHeader) => {
  const handleHeaderBtnClick = () => onActionButtonClick && onActionButtonClick()
  const handleDownloadBtnClick = () => onDownloadClick && onDownloadClick()

  return (
    <DataGridHeaderWrapper data-testid="headerWrapper">
      <Typography data-testid="headerTitle" variant="h4" fontSize={24} fontWeight={300} textTransform={"uppercase"}>
        {t(title)}
      </Typography>
      {showDownloadBtn && (
        <PrimaryButton
          data-testid="headerDownloadButton"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadBtnClick}
          label={t("shared:button.downloadCsv")}
        />
      )}
      <Stack flexDirection="row" alignItems="flex-end">
        {showFilter && (
          <DataGridQuickFilterSearchInput
            data-testid="searchFilterInput"
            InputProps={{ startAdornment: null }}
            placeholder={t("shared:form.filter.label")}
          />
        )}
        {showButton && (
          <Box>
            <FabButton
              data-testid="headerActionButton"
              size="medium"
              disabled={buttonDisabled}
              style={{ opacity: buttonDisabled ? "0.4" : "1" }}
              icon={buttonIcon ?? <PlusIcon fontSize="medium" sx={{ color: "white" }} />}
              onClick={handleHeaderBtnClick}
            />
          </Box>
        )}
      </Stack>
    </DataGridHeaderWrapper>
  )
}
