import React from "react"
import { SvgIconProps } from "@mui/material"
import {
  ProductIcon,
  ProfileIcon,
  DocumentIcon,
  AreaIcon,
  BuildingIcon,
  UtilityUnitIcon,
  MeterIcon,
  BillIcon,
  TaskListIcon,
  EnergySupplierIcon,
} from "../../uikit/SidebarIcons"

enum SelectedNavigation {
  PRODUCTS = "PRODUCT",
  PROFILES = "PROFILES",
  BUILDINGS = "BUILDINGS",
  CONSUMPTION_POINTS = "CONSUMPTION_POINTS",
  CONTRACTS = "CONTRACTS",
  BILLING_INITIAL = "BILLING_INITIAL",
  BILLING_RECURRING = "BILLING_RECURRING",
  TASK_LIST = "TASK_LIST",
  METERS = "METERS",
  UTILITY = "UTILITY",
  AREA = "AREA",
  CONTRACTS_MANAGEMENT = "CONTRACT_MANAGEMENT",
  TARIFF_MANAGEMENT = "TARIFF_MANAGEMENT",
  UNKNOWN = "UNKNOWN",
}

export enum NavItemType {
  BUTTON = "BUTTON",
  HEADER = "HEADER",
  SPACER = "SPACER",
}

export interface AdminNavItem {
  navType: NavItemType
  label?: string
  icon?: (props: SvgIconProps) => React.ReactElement
  selectedLabel?: string
  redirectUrl?: string
}

export const adminNavigationConfig: AdminNavItem[] = [
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.product",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.products",
    icon: ProductIcon,
    selectedLabel: SelectedNavigation.PRODUCTS,
    redirectUrl: "/products",
  },
  {
    navType: NavItemType.SPACER,
  },
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.profile",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.profiles",
    icon: ProfileIcon,
    selectedLabel: SelectedNavigation.PROFILES,
    redirectUrl: "/profiles",
  },
  {
    navType: NavItemType.SPACER,
  },
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.contracts",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.contracts",
    icon: DocumentIcon,
    selectedLabel: SelectedNavigation.CONTRACTS_MANAGEMENT,
    redirectUrl: "/management-contracts",
  },
  {
    navType: NavItemType.SPACER,
  },
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.topology",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.area",
    icon: AreaIcon,
    selectedLabel: SelectedNavigation.AREA,
    redirectUrl: "/area",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.buildings",
    icon: BuildingIcon,
    selectedLabel: SelectedNavigation.BUILDINGS,
    redirectUrl: "/buildings",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.utilityUnit",
    icon: UtilityUnitIcon,
    selectedLabel: SelectedNavigation.UTILITY,
    redirectUrl: "/utility-units",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.meters",
    icon: MeterIcon,
    selectedLabel: SelectedNavigation.METERS,
    redirectUrl: "/meters",
  },
  {
    navType: NavItemType.SPACER,
  },
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.tariff",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.energySupplier",
    icon: EnergySupplierIcon,
    selectedLabel: SelectedNavigation.TARIFF_MANAGEMENT,
    redirectUrl: "/tariff",
  },
  {
    navType: NavItemType.SPACER,
  },
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.billing",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.billingsInitial",
    icon: BillIcon,
    selectedLabel: SelectedNavigation.BILLING_INITIAL,
    redirectUrl: "/billings/initial",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.billingsRecurring",
    icon: BillIcon,
    selectedLabel: SelectedNavigation.BILLING_RECURRING,
    redirectUrl: "/billings/recurring",
  },
  {
    navType: NavItemType.SPACER,
  },
  {
    navType: NavItemType.HEADER,
    label: "appContainer:admin.navigation.title.tasks",
  },
  {
    navType: NavItemType.BUTTON,
    label: "appContainer:admin.navigation.tasklist",
    icon: TaskListIcon,
    selectedLabel: SelectedNavigation.TASK_LIST,
    redirectUrl: "/tasklist",
  },
  {
    navType: NavItemType.SPACER,
  },
]
