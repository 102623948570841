import { DialogContentText, Grid, styled, Typography, Paper } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { isEmpty } from "lodash-es"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom"
import { BuildingAdminResponse, BuildingState, ContractAdminResponse } from "../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../domain/Domain.Model"
import {
  getBuildingById,
  getContractsForBuilding,
} from "../../../../domain/portal/admin/buildings/Buildings.Repository"

import { AlignBottomBox, SpaceBetweenMiddleBox } from "../../../../uikit/box/AlignmentBox"
import { DividerBox } from "../../../../uikit/box/DividerBox"
import { SmallPaddedBox } from "../../../../uikit/box/PaddedBox"
import { PrimaryEditButton } from "../../../../uikit/button/PrimaryEditButton"
import { StatusView } from "../../../../uikit/label/StatusView"
import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { ErrorAlert, SuccessAlert } from "../../../../uikit/Shared.Alert"
import { DeactivateIcon, RemoveIcon } from "../../../../uikit/Shared.Icon"
import { ImpressionHeader } from "../../../../uikit/typography/Header"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { COMPONENTS_CONFIG } from "../sharedComponentsConfig/Components.Config"
import SharedListComponent from "../sharedListComponet/SharedList.Component"
import { mapDispatchToProps } from "./BuildingsDetail.Connect"
import { BuildingsDetailState } from "./BuildingsDetail.Reducer"
import { ConfirmDialog } from "../../../../uikit/confirmDialog/ConfirmDialog"
import { SingleLineDatePicker } from "../../../../uikit/input/SingleLineDatePicker"
import { useQueryDefaultOptions } from "../../../Shared.Utils"

interface BuildingsDetailProps
  extends BuildingsDetailState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

const MasterAddressField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: theme.typography.pxToRem(12),
}))

const MasterAddressValue = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: theme.typography.pxToRem(16),
}))

export const BuildingsDetailComponent = (props: BuildingsDetailProps) => {
  const { t } = useTranslation("buildings")
  const {
    match,
    deactivateBuilding,
    deactivateViewState,
    deleteBuilding,
    deleteViewState,
    navigateToUpdateBuilding,
    showUpdateAlert,
    navigateToCreate,
    navigateToMeterCreate,
  } = props
  const history = useHistory()
  const [deactivateDate, setDeactivateDate] = useState<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: building,
    refetch,
    remove,
  } = useQuery<BuildingAdminResponse, AxiosError>(["getMeter"], () => getBuildingById(match.params?.buildingId), {
    enabled: !!match.params?.buildingId,
    ...useQueryDefaultOptions,
    onError: (err: AxiosError) => err,
  })

  const {
    data: contracts,
    isLoading: isLoadingContracts,
    remove: removeContracts,
  } = useQuery<ContractAdminResponse[], AxiosError>(
    ["getBuildingContracts"],
    () => getContractsForBuilding(match.params?.buildingId),
    {
      enabled: !!match.params?.buildingId,
      onError: (err: AxiosError) => err,
    },
  )
  const hasContractsAssigned = !isEmpty(contracts)
  const isDisabled = hasContractsAssigned || building?.activeState === "INACTIVE"

  useEffect(() => {
    return () => {
      remove()
      removeContracts()
    }
  }, [remove, removeContracts])

  useEffect(() => {
    if (deactivateViewState.domainResult) {
      refetch()
    }
    if (deleteViewState.domainResult) {
      history.push(`/area/${building?.areaId}`)
    }
  }, [refetch, deactivateViewState.domainResult, deleteViewState.domainResult, building?.areaId, history])

  if (isLoading || isLoadingContracts) return <ProgressIndicator />
  if (isError) return <ErrorAlert retry={refetch} message={error?.message || t("error-codes:GENERIC_FATAL_ERROR")} />

  return (
    <>
      {deactivateViewState.domainResult && <SuccessAlert message={t("details.action.deactivate.success")} />}
      {deactivateViewState.domainError && <ErrorAlert message={deactivateViewState.domainError.message} />}
      {deleteViewState.domainResult && <Redirect to="/buildings" />}
      {deleteViewState.domainError && <ErrorAlert message={deleteViewState.domainError.message} />}
      {showUpdateAlert && <SuccessAlert message={t("form.alert.success")} />}
      {building && !isLoading && (
        <>
          <Paper>
            <SpaceBetweenMiddleBox>
              <SmallPaddedBox>
                <StatusView statusType={StatusType[building.activeState]} />
              </SmallPaddedBox>
              {building.activeState !== BuildingState.INACTIVE && (
                <SmallPaddedBox>
                  <PrimaryEditButton onClick={() => navigateToUpdateBuilding(building.id)} />
                </SmallPaddedBox>
              )}
            </SpaceBetweenMiddleBox>
            <SpaceBetweenMiddleBox>
              <ImpressionHeader>{building.name}</ImpressionHeader>
            </SpaceBetweenMiddleBox>
            <Grid container>
              <Grid item xs={6} px={2}>
                <MasterAddressField>{t("form.field.addressStreet")}</MasterAddressField>
                <MasterAddressValue>{building.address?.street}</MasterAddressValue>
              </Grid>
              <Grid item xs={6} px={2}>
                <MasterAddressField>{t("form.field.addressHouseNumber")}</MasterAddressField>
                <MasterAddressValue>{building.address?.houseNumber}</MasterAddressValue>
              </Grid>
              <Grid item xs={6} px={2}>
                <MasterAddressField>{t("form.field.addressPostCode")}</MasterAddressField>
                <MasterAddressValue>{building.address?.postalCode}</MasterAddressValue>
              </Grid>
              <Grid item xs={6} px={2}>
                <MasterAddressField>{t("form.field.addressCity")}</MasterAddressField>
                <MasterAddressValue>{building.address?.city}</MasterAddressValue>
              </Grid>
            </Grid>
            <DividerBox />
            <SmallPaddedBox>
              <AlignBottomBox>
                <ConfirmDialog
                  actionButtonText={t("details.action.delete")}
                  actionButtonStartIcon={<RemoveIcon fontSize="large" />}
                  actionButtonLoading={deleteViewState.isLoading}
                  actionButtonDisabled={isDisabled}
                  onConfirm={() => deleteBuilding(building.id)}
                  confirmButtonText={t("details.action.delete")}
                  dialogTitle={t("details.action.dialogTitle")}
                  dialogBodyText={t("details.delete.dialogText")}
                />
                <DividerBox />
                <ConfirmDialog
                  actionButtonText={t("details.action.deactivate")}
                  actionButtonStartIcon={<DeactivateIcon fontSize="large" />}
                  actionButtonLoading={deactivateViewState.isLoading}
                  actionButtonDisabled={isDisabled}
                  confirmButtonDisabled={!deactivateDate}
                  confirmButtonText={t("details.action.deactivate")}
                  dialogTitle={t("details.action.dialogTitle")}
                  dialogBodyText={t("details.deactivate.dialogText")}
                  dialogBody={
                    <>
                      <DialogContentText>{t("details.deactivate.dialogText")}</DialogContentText>
                      <SingleLineDatePicker
                        required
                        name="from"
                        label={t("form.field.billableUntilDate")}
                        value={deactivateDate}
                        onChange={setDeactivateDate}
                        minDate={new Date()}
                      />
                    </>
                  }
                  onConfirm={() => deactivateBuilding(building.id, deactivateDate)}
                />
              </AlignBottomBox>
            </SmallPaddedBox>
          </Paper>
          <SharedListComponent configNew={COMPONENTS_CONFIG.buildingContracts} queryParam={building.id} />
          <SharedListComponent
            configNew={COMPONENTS_CONFIG.buildingUtilityUnits}
            onHeaderBtnClick={() => navigateToCreate(building.id)}
            queryParam={building.id}
          />
          <SharedListComponent
            queryParam={building.id}
            configNew={COMPONENTS_CONFIG.buildingMetersPoints}
            onHeaderBtnClick={() => navigateToMeterCreate(building.id)}
          />
        </>
      )}
    </>
  )
}
