import { useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { CircularProgress, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"

import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { PrimaryButton } from "../../../../uikit/button/PrimaryButton"
import { PrimaryOutlinedButton } from "../../../../uikit/button"
import { VewaBillingDoneBills } from "../../shared/vewaBillings/VewaBillingDoneBills"
import {
  adminGetVewaParticipantBillingBills,
  adminVewaParticipantBillsDownloadCsv,
} from "../../../../domain/portal/admin/billings/participant/Bill.Repository"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const VewaParticipantsDoneBillsPage = ({ match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId } = params
  const { t } = useTranslation("settlements")
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: bill,
    remove: removeBill,
    isFetching,
  } = useQuery(["getVewaBill"], () => adminGetVewaParticipantBillingBills(billingId), {
    enabled: !!billingId,
    onError: setError,
  })

  const {
    mutate: downloadCSV,
    isLoading: isDownloadingCSV,
    isError: isDownloadCsvError,
  } = useMutation(["adminDownloadVewaCsv"], () => adminVewaParticipantBillsDownloadCsv(billingId))

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  return (
    <Paper>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error || isDownloadCsvError}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      {isFetching || !bill ? (
        <CircularProgress />
      ) : (
        <>
          <VewaBillingDoneBills {...bill} />
          <Stack py={6} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            <PrimaryOutlinedButton
              onClick={() => downloadCSV()}
              isLoading={isDownloadingCSV}
              label={t("bills.downloadCSV")}
              sx={{ mr: 2 }}
            />
            <PrimaryButton label={t("bills.downloadAllBills")} />
          </Stack>
        </>
      )}
    </Paper>
  )
}
