import React, { useEffect, useState } from "react"
import { Stack } from "@mui/material"
import { IconPositionEnum, PrimaryTab, PrimaryTabContainer } from "../../../../../../uikit/tabs/PrimaryTab"
import { useTranslation } from "react-i18next"
import { DotIcon } from "../../../../../../uikit/icons/Icons"
import { MediumColorEnum } from "../Medium.Enums"
import { useQuery } from "@tanstack/react-query"
import { getMediumZevByContractId } from "../../../../../../domain/portal/admin/mediu/Medium.Repository"
import { useQueryDefaultOptions } from "../../../../../Shared.Utils"
import { getMeters } from "../../../../../../domain/portal/admin/meters/Meters.Repository"
import { DataItemBoxNewDesign } from "../../../../../../uikit/box/DataItemBox"
import { Subtitle1 } from "../../../../../../uikit/typography/Typography"
import { MeterResponse } from "../../../../../../data/generated-sources/openapi"
import MediumZevTableComponent from "./MediumZevTable.Component"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { AxiosError, AxiosResponse } from "axios"

const MediumZevComponent = ({ contractId }: { contractId: string }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { t } = useTranslation("medium")
  const {
    data: mediumZevData,
    isError: mediumZevIsError,
    isLoading: mediumZevIsLoading,
    remove: mediumZevRemove,
  } = useQuery<AxiosResponse, AxiosError>(["getMediumZevByContractId"], () => getMediumZevByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
  })
  const {
    data: metersData,
    isError: metersIsError,
    isLoading: meterIsLoading,
    remove: meterRemove,
  } = useQuery<AxiosResponse, AxiosError>(["getMeters"], () => getMeters(1, 10000, ""), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
  })
  const collectMeters = (meterIds: string[] | undefined) => {
    if (!meterIds || !meterIds.length || !metersData?.data?.elements || metersIsError || mediumZevIsError) {
      return []
    }
    return metersData.data.elements.filter((item: MeterResponse) => meterIds.includes(item.id))
  }

  useEffect(() => {
    return () => {
      mediumZevRemove()
      meterRemove()
    }
  }, [mediumZevRemove, meterRemove])

  return (
    <>
      <Stack spacing={3}>
        <PrimaryTabContainer
          value={selectedTab}
          sx={{ paddingLeft: 0 }}
          onChange={(_, newValue) => setSelectedTab(newValue)}
        >
          <PrimaryTab
            label={t("tab.label.ELECTRICITY")}
            iconPosition={IconPositionEnum.END}
            icon={<DotIcon color={MediumColorEnum.ELECTRICITY} />}
          ></PrimaryTab>
        </PrimaryTabContainer>

        <ErrorAlert visible={mediumZevIsError || metersIsError} message={t("error-codes:GENERIC_FATAL_ERROR")} />

        <DataItemBoxNewDesign title={t("label.municipality")} value={mediumZevData?.data.municipality} />

        <Subtitle1>{t("label.incomingMeters")}</Subtitle1>
        <MediumZevTableComponent
          meters={collectMeters(mediumZevData?.data.incomingMeters)}
          isLoading={meterIsLoading || mediumZevIsLoading}
          tableId="ckw-medium-incoming-meters-table"
        />

        <Subtitle1>{t("label.outgoingMeters")}</Subtitle1>
        <MediumZevTableComponent
          meters={collectMeters(mediumZevData?.data.outgoingMeters)}
          isLoading={meterIsLoading || mediumZevIsLoading}
          tableId="ckw-medium-outgoing-meters-table"
        />
      </Stack>
    </>
  )
}

export default MediumZevComponent
