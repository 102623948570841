import { from, map, mergeMap, Observable } from "rxjs"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import {
  BuildingAdminResponse,
  ContractAdminResponse,
  UtilityUnitCreateAdminRequest,
  UtilityUnitResponse,
} from "../../../../data/generated-sources/openapi"
import { apiCall, apiHeaders } from "../../../Domain.Calls"
import { DomainDependencies } from "../../../Domain.Dependencies"
import { timestampToApiFormattedDate } from "../../../Domain.Formatters"
import { StatusType } from "../../../Domain.Model"
import { DomainResponse } from "../../../Domain.Response"
import { buildingsListMapper } from "./Buildings.Mapper"
import { BuildingsList, BuildingUpsert } from "./Buildings.Model"
import { getAllParticipationsForUtilityUnit } from "../utility-units/UtilityUnits.Repository"

export const getBuildings = (deps: DomainDependencies): Observable<DomainResponse<BuildingsList>> => {
  const headers = apiHeaders(deps)

  return apiCall(
    from(deps.adminBuildingApi.adminGetBuildings(1, 10000, undefined, headers)).pipe(
      mergeMap((buildingsResponse) =>
        from(deps.adminAreaApi.adminGetAreas(1, 10000, undefined, headers)).pipe(
          map((areaResponse) => buildingsListMapper(buildingsResponse?.data, areaResponse?.data?.elements)),
        ),
      ),
    ),
  )
}

export const getBuildingById = async (
  buildingId: string,
  deps?: DomainDependencies,
): Promise<BuildingAdminResponse> => {
  const dependencies = deps || DOMAIN_DEPENDENCIES
  const headers = apiHeaders(dependencies)
  const { data } = await dependencies.adminBuildingApi.adminGetBuildingById(buildingId, headers)
  return data
}

export const createBuilding = (areaId: string, buildingUpsert: BuildingUpsert, deps: DomainDependencies) => {
  return apiCall(
    from(
      deps.adminAreaApi.adminCreateBuildingForArea(
        areaId,
        {
          name: buildingUpsert.buildingObject,
          address: {
            street: buildingUpsert.addressStreet,
            houseNumber: buildingUpsert.addressHouseNumber,
            postalCode: buildingUpsert.addressPostCode,
            city: buildingUpsert.addressCity,
          },
        },
        apiHeaders(deps),
      ),
    ).pipe(map((url) => url.data.split("/").pop() ?? "")),
  )
}

export const updateBuilding = (buildingId: string, buildingUpsert: BuildingUpsert, deps: DomainDependencies) => {
  return apiCall(
    from(
      deps.adminBuildingApi.adminUpdateBuildingById(
        buildingId,
        {
          name: buildingUpsert.buildingObject,
          address: {
            street: buildingUpsert.addressStreet,
            houseNumber: buildingUpsert.addressHouseNumber,
            postalCode: buildingUpsert.addressPostCode,
            city: buildingUpsert.addressCity,
          },
        },
        apiHeaders(deps),
      ),
    ).pipe(map(() => true)),
  )
}

export const getBuildingUpdateById = (
  buildingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<BuildingUpsert>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminBuildingApi.adminGetBuildingById(buildingId, headers)).pipe(
      map((buildingsResponse) => ({
        statusType: StatusType[buildingsResponse.data.activeState],
        buildingObject: buildingsResponse.data.name ?? "",
        addressStreet: buildingsResponse.data.address.street,
        addressHouseNumber: buildingsResponse.data.address.houseNumber,
        addressPostCode: buildingsResponse.data.address.postalCode,
        addressCity: buildingsResponse.data.address.city,
      })),
    ),
  )
}

export const deactivateBuilding = (buildingId: string, fromDate: number, deps: DomainDependencies) => {
  return apiCall(
    from(
      deps.adminBuildingApi.adminDeactivateBuildingById(
        buildingId,
        timestampToApiFormattedDate(fromDate, deps),
        apiHeaders(deps),
      ),
    ).pipe(map(() => true)),
  )
}

export const deleteBuilding = (buildingId: string, deps: DomainDependencies) => {
  return apiCall(from(deps.adminBuildingApi.adminDeleteBuilding(buildingId, apiHeaders(deps))).pipe(map(() => true)))
}

export const createBuildingUtilityUnit = (buildingId: string, createUnitData: UtilityUnitCreateAdminRequest) => {
  return DOMAIN_DEPENDENCIES.adminBuildingApi.adminCreateUtilityUnitForBuilding(
    buildingId,
    createUnitData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getContractsForBuilding = async (
  buildingId: string,
  deps?: DomainDependencies,
): Promise<ContractAdminResponse[]> => {
  const dependencies = deps || DOMAIN_DEPENDENCIES
  const headers = apiHeaders(dependencies)
  const { data } = await dependencies.adminBuildingApi.adminGetContractForBuilding(
    buildingId,
    1,
    undefined,
    undefined,
    headers,
  )
  return data.elements
}

export const getUtilityUnitForBuilding = async (
  buildingId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const authHeaders = apiHeaders(DOMAIN_DEPENDENCIES)

  const { data: units } = await DOMAIN_DEPENDENCIES.adminBuildingApi.adminGetUtilityUnitForBuilding(
    buildingId,
    page,
    limit,
    orderBy,
    authHeaders,
  )

  const mappedUnits = await Promise.allSettled(
    units.elements.map(async (unit) => {
      const participantData = await getAllParticipationsForUtilityUnit(unit.id)

      return {
        ...unit,
        participationParticipants: participantData,
      }
    }),
  )

  const fulfilledResult = mappedUnits
    .filter((el) => el.status === "fulfilled")
    .map((e) => (e as PromiseFulfilledResult<UtilityUnitResponse>)?.value)

  const data = {
    page: units.page,
    elements: fulfilledResult,
  }
  return { data }
}
