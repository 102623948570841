import { useEffect, useState } from "react"
import { VewaBillingDoneBills } from "../../shared/vewaBillings/VewaBillingDoneBills"
import DateUtils from "../../../../services/utils/DateUtils"
import { setPageSubtitle } from "../../../appcontainer/AppContainer.Epic"
import {
  customerGetVewaParticipantBillingBills,
  customerVewaParticipantBillsDownloadCsv,
} from "../../../../domain/portal/manager/bills/Bills.Repository"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../hooks"
import { RouteComponentProps } from "react-router-dom"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { Box, CircularProgress, Stack } from "@mui/material"
import { PrimaryButton, PrimaryOutlinedButton } from "../../../../uikit/button"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { useHotJarScript } from "../hooks/useHotJarScript"

export const VewaParticipantsDoneBills = ({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { billingId } = params
  const { t } = useTranslation("settlements")
  const dispatch = useAppDispatch()
  const [error, setError] = useState<AxiosErrorDataType>()

  // Hotjar
  useHotJarScript()

  const {
    data: bill,
    isFetching,
    remove: removeBill,
  } = useQuery(["getVewaBill"], () => customerGetVewaParticipantBillingBills(billingId), {
    enabled: !!billingId,
    onError: setError,
  })

  const {
    mutate: downloadCSV,
    isLoading: isDownloadingCSV,
    isError: isDownloadCsvError,
  } = useMutation(["customerDownloadVewaCsv"], () => customerVewaParticipantBillsDownloadCsv(billingId))

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  const billDateRange = `${DateUtils.getDeFormatDate(bill?.startDate || "", "DD. MMM")} - ${DateUtils.getDeFormatDate(
    bill?.endDate || "",
    "DD. MMM YY",
  )}`

  bill && dispatch(setPageSubtitle(billDateRange))

  return (
    <Box p={6}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error || isDownloadCsvError}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      {isFetching || !bill ? (
        <CircularProgress />
      ) : (
        <>
          <VewaBillingDoneBills {...bill} />
          <Stack py={6} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            <PrimaryOutlinedButton
              onClick={() => downloadCSV()}
              isLoading={isDownloadingCSV}
              label={t("bills.downloadCSV")}
              sx={{ mr: 2 }}
            />
            <PrimaryButton label={t("bills.downloadAllBills")} />
          </Stack>
        </>
      )}
    </Box>
  )
}
