import React from "react"
import {
  Medium,
  MediumZevAdminUpdateRequest,
  ZevMeter,
  ZevMeterType,
} from "../../../../../../../data/generated-sources/openapi"
import { Grid } from "@mui/material"
import { DotIcon } from "../../../../../../../uikit/icons/Icons"
import { MediumColorEnum, mediumZevMeterTypeValues, mediumZevTypeValues } from "../../Medium.Enums"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { MediumCardDetailComponentInterface } from "../../Medium.Interfaces"
import { SingleLineTextField } from "../../../../../../../uikit/input/SingleLineTextField"
import { SelectPicker } from "../../../../../../../uikit/input/SelectPicker"
import { SingleLineDatePicker } from "../../../../../../../uikit/input/SingleLineDatePicker"
import DateUtils from "../../../../../../../services/utils/DateUtils"
import { RemoveIcon } from "../../../../../../../uikit/Shared.Icon"

const MediumZevCardDetailComponent = (props: MediumCardDetailComponentInterface<ZevMeter>) => {
  const {
    index,
    setIsFormDirty,
    formikProps: { setFieldValue, handleBlur, values, errors },
  } = props
  const { t } = useTranslation("medium")
  const { values: formikValues } = useFormikContext<MediumZevAdminUpdateRequest>()

  const removeMedium = () => {
    if (formikValues?.meters?.length > 1) {
      const mediums: ZevMeter[] = formikValues.meters.filter((item: ZevMeter, i: number) => i !== index)
      !!setFieldValue && setFieldValue("meters", mediums)
      !!setIsFormDirty && setIsFormDirty(true)
    }
  }

  const renderRemoveIcon = () => {
    if (
      (values.meterType === ZevMeterType.OUTGOING &&
        formikValues.meters.filter((item: ZevMeter) => item.meterType === ZevMeterType.OUTGOING).length < 2) ||
      (values.meterType === ZevMeterType.INCOMING &&
        formikValues.meters.filter((item: ZevMeter) => item.meterType === ZevMeterType.INCOMING).length < 2)
    ) {
      return null
    }
    return <RemoveIcon onClick={removeMedium} sx={{ cursor: "pointer" }} />
  }

  return (
    <>
      <Grid
        item
        xs={3.5}
        sx={{
          height: "400px",
          p: 2,
          mb: 2,
          mr: 2,
          border: "1px solid #EFEFEF",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
        }}
      >
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <DotIcon color={MediumColorEnum[Medium.ELECTRICITY]} />
          {renderRemoveIcon()}
        </Grid>
        <Grid item xs={12}>
          <SelectPicker
            name="type"
            type="text"
            label={t("detail.title")}
            value={Medium.ELECTRICITY}
            items={mediumZevTypeValues.map((key) => {
              return {
                label: t(`tab.label.${key}`),
                value: key,
              }
            })}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectPicker
            name="type"
            type="text"
            label={t("label.deliveryType")}
            value={values.meterType}
            items={mediumZevMeterTypeValues.map((key: string) => {
              return {
                label: t(`label.${key}`),
                value: key,
              }
            })}
            onChange={(e) => setFieldValue(`meters[${index}].meterType`, e.target.value)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <SingleLineTextField
            name={"meteringCode"}
            id={"meteringCode"}
            type={"text"}
            helperText={errors.meteringCode ? errors.meteringCode : undefined}
            value={values.meteringCode ?? ""}
            onChange={(e) => {
              setFieldValue(`meters[${index}].meteringCode`, e.target.value)
            }}
            onBlur={handleBlur}
            label={t("table.header.deliveryId")}
          />
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={5.5}>
            <SingleLineDatePicker
              name="dateFrom"
              label={t("table.header.startDate")}
              value={DateUtils.getTimeStamp(values.dateFrom)}
              helperText={errors.dateFrom ? errors.dateFrom : undefined}
              onBlur={handleBlur}
              onChange={(value) => setFieldValue(`meters[${index}].dateFrom`, value <= 0 ? 0 : value)}
            />
          </Grid>
          <Grid item xs={5.5}>
            <SingleLineDatePicker
              name="dateTo"
              label={t("table.header.endDate")}
              value={values?.dateTo ? DateUtils.getTimeStamp(values.dateTo) : 0}
              helperText={errors.dateTo ? errors.dateTo : undefined}
              onBlur={handleBlur}
              onChange={(value) => setFieldValue(`meters[${index}].dateTo`, value <= 0 ? 0 : value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MediumZevCardDetailComponent
