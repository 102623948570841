import { ButtonTypeMap } from "@mui/material/Button"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { GradientButton } from "./GradientButton"
import { CircularProgress } from "@mui/material"

export interface PrimaryButtonProps {
  id?: string
  label: string
  isLoading?: boolean
}

export const PrimaryButton = (props: PrimaryButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { isLoading, endIcon, disabled, ...rest } = props
  return (
    <GradientButton
      startColor={"#9AB20F"}
      endColor={"#4E7818"}
      disabled={disabled || isLoading}
      sx={{
        fontWeight: 500,
        fontSize: "16px",
        padding: "12px 18px",
      }}
      endIcon={(isLoading && <CircularProgress style={{ color: "white" }} size={20} />) || endIcon}
      useNewDesign
      {...rest}
    />
  )
}
