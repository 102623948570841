import { createTheme, PaletteOptions, Theme } from "@mui/material"
import { DataGridPropsWithDefaultValues } from "@mui/x-data-grid/internals"
import type {} from "@mui/x-data-grid/themeAugmentation"

export const appThemePrimaryColor = "#365F10"
export const appThemePrimaryLightColor = "#C8E6A4"
export const appThemePrimaryLighterColor = "#FBFCE2"
export const appThemePrimaryDarkColor = "#21420B"

export const newDesignSelectColor = "#F1F9E8"
export const newDesignSelectColorDark = "#4F544F"
export const whiteColor = "#ffffff"
export const solarPrimaryColor = "#53821F"

export const appThemeSecondaryColor = "#A7B805"
export const appThemeSecondaryLightColor = "#6c9c30"
export const appThemeSecondaryLighterColor = "#D5E45E"
export const appThemeWarningColor = "#ffa726"
export const appThemeWarningDarkColor = "#f57c00"
export const appThemeErrorColor = "#ec6a0a"
export const appThemeErrorDarkColor = "#de3f1a"

export const appThemeGrey10 = "#fafafa"
export const appThemeGrey40 = "#eee"
export const appThemeGrey50 = "#ededed"
export const appThemeGrey60 = "#EFF0EF"
export const appThemeGrey100 = "#777"
export const appThemeGrey200 = "#515151"
export const appThemeGrey250 = "#3C403C"
export const appThemeGrey400 = "#8D968D"
export const appThemeGrey800 = "#232623"
export const appThemeGreyNewDesign = "#979797"

export const chartMainBlueColor = "#4197D5"
export const chartMainGreenColor = "#CDD756"
export const chartMainBlueLightColor = "#95CEF1"
export const chartLightGreenColor = "#ecf59e"
export const chartLightBlueColor = "#83D0F5"
export const chartLightPurpleColor = "#C692BC"
export const chartLightBeigeColor = "#fbe6d4"
export const chartDarkGreenColor = "#BCCF02"

export const mediumPurpleColor = "#901A7D"
export const mediumPurpleLightColor = "#F2E7F0"
export const mediumPurpleMidColor = "#B161A2"
export const mediumBlueColor = "#0065DB"
export const mediumBlueLightColor = "#0099DB"
export const mediumGrayColor = "#EEF1F2"
export const mediumPinkColor = "#FEEFEF"
export const mediumPinkLightColor = "#fed8d8"
export const mediumRedColor = "#DE1A1A"
export const mediumYellowColor = "#FDC300"
export const mediumGreenColor = "#c9d935"

export const bgColorGreen = "#FBFCE2"
export const colorBlack = "#000000"

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    lightPrimary?: string
    editPrimary?: string
  }

  interface PaletteColor {
    lighter?: string
    lightest?: string
  }
  interface SimplePaletteColorOptions {
    lighter?: string
    lightest?: string
  }
}

type OwnerState = Partial<DataGridPropsWithDefaultValues> & {
  tariff?: boolean
  admin?: boolean
}

const palette: PaletteOptions = {
  primary: {
    main: appThemePrimaryColor,
    light: appThemePrimaryLightColor,
    dark: appThemePrimaryDarkColor,
  },
  secondary: {
    main: appThemeSecondaryColor,
    light: appThemeSecondaryLightColor,
    lighter: appThemeSecondaryLighterColor,
    dark: appThemeGreyNewDesign,
  },
  text: {
    primary: "#1a1a1a",
    secondary: appThemeGrey800,
  },
  action: {
    disabled: "#888888",
  },
  background: {
    lightPrimary: appThemePrimaryLighterColor,
    editPrimary: bgColorGreen,
  },
  error: {
    main: appThemeErrorDarkColor,
  },
}

export const ckwTheme = createTheme({
  palette: {
    ...palette,
  },
  typography: (palette) => ({
    allVariants: {
      fontFamily: ["Montserrat", "Arial", "sans-serif", "serif"].join(","),
      color: palette.text.primary,
      fontWeight: 500,
      lineHeight: 1.25,
    },
    subtitle1: {
      fontWeight: 300,
    },
  }),
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: bgColorGreen,
          padding: "30px",
          ".MuiAlertTitle-root": {
            color: appThemePrimaryColor,
            fontWeight: 400,
            fontSize: "1.2rem",
            lineHeight: "1rem",
            marginBottom: "15px",
          },
          ".MuiBox-root": {
            fontWeight: 400,
            lineHeight: "1.4rem",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: "primary",
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: () => ({
          ":disabled": {
            opacity: 0.4,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        color: "primary",
        variant: "contained",
        disableElevation: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 500,
          fontSize: "16px",
          borderRadius: "100px",
          textTransform: "none",
          padding: theme.spacing(1, 2),
          ":disabled": {
            opacity: 0.7,
          },
        }),
        containedPrimary: ({ theme }) => ({
          color: "white",
          ":disabled": {
            color: "white",
            cursor: "not-allowed",
            backgroundColor: theme.palette.primary.main,
          },
          ":hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
        outlinedPrimary: ({ theme }) => ({
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          padding: theme.spacing(1.5, 2.25),
          ":disabled": {
            cursor: "not-allowed",
            color: theme.palette.secondary.main,
          },
          ":hover": {
            backgroundColor: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiDialogTitle-root.MuiTypography-root": {
            padding: 0,
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(24),
            color: theme.palette.text.primary,
            paddingBottom: theme.typography.pxToRem(32),
          },
          ".MuiTypography-root.MuiDialogContentText-root, .MuiDialogContent-root": {
            fontWeight: 400,
            padding: 0,
            fontSize: theme.typography.pxToRem(15),
            paddingBottom: theme.spacing(4),
          },
          ".MuiDialogActions-root": {
            padding: 0,
            alignItems: "center",
            justifyContent: "flex-start",
          },
        }),
        paper: ({ theme }) => ({
          padding: theme.spacing(7),
          borderRadius: "1rem",
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          ".MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            ".MuiDataGrid-cell--withRenderer": {
              outline: "none !important",
            },
          },
        },
        virtualScroller: {
          paddingBottom: "5rem",
        },
        columnHeaderTitle: {
          fontWeight: 600,
          color: "#000000",
        },
        columnHeaders: ({ ownerState, theme }: { ownerState: OwnerState; theme: Theme }) => ({
          ...(ownerState?.tariff && {
            backgroundColor: "#EFF0EF",
            borderBottom: "none",
            borderRadius: theme.spacing(1),
          }),
        }),
        columnHeader: ({ ownerState, theme }: { ownerState: OwnerState; theme: Theme }) => ({
          borderBottom: "2px solid #B7B7B7",
          ":hover, :focus, :focus-within": {
            outline: "none",
          },
          ...(ownerState?.admin && {
            textTransform: "uppercase",
          }),
          ...(ownerState?.tariff && {
            padding: theme.spacing(1.5, 3),
            borderBottom: "none",
          }),
        }),
        columnSeparator: {
          display: "none",
        },
        row: ({ ownerState, theme }: { ownerState: OwnerState; theme: Theme }) => ({
          ":hover": {
            background: theme.palette.background.lightPrimary,
          },
          "&.RowNotClickable": {
            cursor: "auto",
          },
          "&.RowClickable": {
            cursor: "pointer",
          },
          "&.Mui-selected": {
            backgroundColor: "#fafafb",
          },
          ...(ownerState?.admin && {
            cursor: "pointer",
          }),
          ...(ownerState?.tariff && {
            ":last-child": {
              ".MuiDataGrid-cell": {
                borderBottom: "none",
              },
            },
          }),
        }),
        cell: ({ ownerState, theme }: { ownerState: OwnerState; theme: Theme }) => ({
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          color: "#000000",
          ":focus, :focus-within": {
            outline: "none",
          },
          ".MuiDataGrid-actionsCell": {
            transform: "rotate(90deg)",
          },

          ...(ownerState?.tariff && {
            padding: theme.spacing(3),
            alignItems: "flex-start",
          }),
        }),
        footerContainer: () => ({
          border: "none",
          ".MuiTablePagination-root": {
            ".MuiToolbar-root, .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
              fontSize: "12px",
            },
            ".MuiSvgIcon-root": {
              fontSize: "20px",
            },
            ".MuiTablePagination-select": {
              display: "flex",
              alignItems: "center",
            },
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: "42px",
          height: "26px",
          padding: 0,
          paddingBottom: "2px",
          marginRight: "10px",
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",

              "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.primary.main,
                height: "22px",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            color: "white",
            width: "20px",
            height: "20px",
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#CBD5E0",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 400,
          lineHeight: theme.typography.pxToRem(14),
          fontSize: theme.typography.pxToRem(12),
          color: "#000000",
          "&.Mui-focused": {
            color: "#000000",
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiTypography-root": {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          "&.Mui-focused, &.Mui-active": {
            "&::before, &::after": {
              borderBottom: "1px solid  rgba(0, 0, 0, 0.42)",
            },
          },
        }),
        input: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontSize: theme.typography.pxToRem(16),
        }),
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiStepConnector-root": {
            "& .MuiStepConnector-line": {
              borderTopWidth: theme.spacing(1),
            },
            "&.Mui-active, &.Mui-completed": {
              "& .MuiStepConnector-line": {
                borderColor: theme.palette.primary.main,
                borderTopWidth: theme.spacing(1),
              },
            },
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),
          marginBottom: theme.spacing(3.375),
          borderRadius: theme.spacing(3),
          boxShadow: "0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A",
        }),
      },
    },
  },
})
