import { DomainDependencies } from "../../../../Domain.Dependencies"
import { from, map, mergeMap, Observable } from "rxjs"
import { DomainResponse } from "../../../../Domain.Response"
import { apiCall, apiHeaders } from "../../../../Domain.Calls"
import { BillingsInitialDetail, BillingsInitialList } from "./BillingsInitial.Model"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"
import { billingsInitialListMapper, billingsInitialMapper } from "./BillingsInitial.Mapper"
import {
  ContractAdminResponse,
  MultiServiceBillingAdminResponse,
  PagedAreaAdminResponse,
  ServiceBillingAdminResponse,
} from "../../../../../data/generated-sources/openapi"
import { AxiosResponse } from "axios"

export const getInitialBillings = (deps: DomainDependencies): Observable<DomainResponse<BillingsInitialList>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminServiceBillingsApi.getAdminServiceBillings(headers)).pipe(
      mergeMap((billingResponse: AxiosResponse<MultiServiceBillingAdminResponse>) =>
        from(deps.adminAreaApi.adminGetAreas(1, 10000, "", headers)).pipe(
          map((areaResponse: AxiosResponse<PagedAreaAdminResponse>) =>
            billingsInitialListMapper(billingResponse.data, areaResponse.data, deps),
          ),
        ),
      ),
    ),
  )
}

export const getOnlyInitialBillingById = (billingId: string) => {
  const headers = apiHeaders(DOMAIN_DEPENDENCIES)
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingById(billingId, headers)
}

export const getInitialBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<BillingsInitialDetail>> => {
  const headers = apiHeaders(deps)
  return apiCall(
    from(deps.adminServiceBillingsApi.getAdminServiceBillingById(billingId, headers)).pipe(
      mergeMap((billingResponse: AxiosResponse<ServiceBillingAdminResponse>) =>
        from(deps.adminContractApi.adminGetContractById(billingResponse.data.contractId, headers)).pipe(
          map((contractResponse: AxiosResponse<ContractAdminResponse>) =>
            billingsInitialMapper(billingResponse.data, contractResponse.data, deps),
          ),
        ),
      ),
    ),
  )
}

export const recalculateInitialBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.recalculateAdminServiceBillingById(billingId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const approveInitialBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.adminServiceBillingsApi.putAdminServiceBillingStateChangeById(billingId, "approve", apiHeaders(deps)),
    ).pipe(map(() => true)),
  )
}

export const cancelInitialBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.adminServiceBillingsApi.putAdminServiceBillingStateChangeById(billingId, "cancel", apiHeaders(deps)),
    ).pipe(map(() => true)),
  )
}

export const submitInitialBillingToSAP = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.sapSendAdminServiceBillingById(billingId, apiHeaders(deps))).pipe(
      map(() => true),
    ),
  )
}

export const deleteInitialBillingById = (
  billingId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.adminServiceBillingsApi.deleteAdminServiceBillingById(billingId, apiHeaders(deps))).pipe(map(() => true)),
  )
}
